import { useState, useEffect } from "react";
import { getMemberQuestion } from "../../libs/dataFunctions";
import { useForm } from "react-hook-form";
import PhoneCodeList from '../../libs/phonecodes';
import { useAuthState } from "../../contexts/AuthContext";
import { useAuthDispatch, updateMemberUser } from "../../contexts/AuthProvider";
import Spinner from "../../components/Spinner/Spinner";

function UpdateDetailForm({team, refreshTeam}){
    const [questions,setQuestions] = useState([]);
    const [questionSanitized, setQuestionSanitized] = useState([]);
    const [userArr, setUserArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const userDetails = useAuthState();
    const dispatch = useAuthDispatch();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    async function refreshQuestions(){
        const [questionsData] = await Promise.all([
            getMemberQuestion(token, team._id)
        ]);
        setQuestions(questionsData.data);
        setUserArr(questionsData.user_details);

        let qsArr=[];
        questionsData.data.forEach((item)=>{
            qsArr.push({name:sanitizeKey(item.name), value:item.name});
        });

        setQuestionSanitized(qsArr);
    }

    function sanitizeKey(key) {
        return key
          .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
          .replace(/\s+/g, '_')          // Replace spaces with underscores
          .toLowerCase();                // Optional: Make it lowercase
    }

    const onSubmit = async(data) => {   
        //setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_member_info");
        formdata.append("team_id", team._id);
        formdata.append("firstName", data.firstName);
        formdata.append("lastName", data.lastName);
        formdata.append("phoneNumber", data.phone);
        formdata.append("phoneCode", data.phonecode);
        formdata.append('sub',userArr.sub);

        if(data.answers!==undefined){
            let custom_fields_name=Object.keys(data.answers);
            let custom_fields_value=Object.values(data.answers);
            var custom_fields_name_array=[];
            custom_fields_name.forEach((item)=>{
                let q = questionSanitized.filter((qs)=>qs.name===item);
                custom_fields_name_array.push(q[0].value);
            });

            formdata.append("custom_fields_name", custom_fields_name_array.join('@#'));
            formdata.append("custom_fields_value", custom_fields_value.join('@#'));
        }
        formdata.append("separate_by", '@#');
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        
        await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {    
            setLoading(false);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');            
            try {
                let rdata = JSON.parse(result);
                
                userDetails.user.LastName = rdata.data.lastName;
                userDetails.user.FirstName = rdata.data.firstName;
                userDetails.user.phoneNumber = rdata.data.phone;
                userDetails.user.phoneCode = rdata.data.phonecode;
                let response2 = updateMemberUser(dispatch, userDetails);
                
                if(rdata.is_need_to_update_team_member_question){
                    refreshQuestions();
                }
                else{
                    refreshTeam();
                }
            } catch (error) {
                console.log(error);
            }
        })
        .catch(error => console.log('error', error));
    }
    useEffect(()=>{
        refreshQuestions();        
    },[]);

    useEffect(()=>{
        setValue('firstName',userArr.firstName);
        setValue('lastName',userArr.lastName);
        setValue('phonecode',userArr.phoneCode);
        setValue('phone',userArr.phoneNumber);
        if(questions.length > 0){
            questions.forEach(question => {
                setValue(`answers.${sanitizeKey(question.name)}`,question.value);
            });
        }
    },[questions, userArr]);

    return(
        !loading ?
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                <div className="form-heading text-center mb-[30px]">Update Your Details</div>
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 md:pr-2">
                        <div className="mb-2.5 text-[14px]">First Name</div>
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="first-name" placeholder="First name *" className='text-field'
                                {...register("firstName",{required: {value:true, message:"First name is required"}})}
                                aria-invalid={errors.firstName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.firstName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.firstName?.message}</p>}
                    </div>
                    <div className="w-full md:w-1/2 md:pl-2">
                        <div className="mb-2.5 text-[14px]">Last Name</div>
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="last-name" placeholder="Last name *" className='text-field'
                                {...register("lastName",{required: {value:true,message:"Last name is required"}})}
                                aria-invalid={errors.lastName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.lastName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.lastName?.message}</p>}
                    </div>
                </div>
                <div className="mb-2.5 text-[14px]">Contact No.</div>
                <div className='flex flex-wrap'>
                    <div className='w-full sm:w-1/3 mb-5 sm:pr-2.5 no-field-wrap'>
                        <div className='form-field-wrap relative'>
                            <select {...register("phonecode",{required: {value:true,message:"Phone code is required"}})} onChange={(e)=>setValue('phonecode', e.target.value, { shouldValidate: true })}>
                                <option value="">Code *</option>
                                {PhoneCodeList.map((c,index)=>{
                                    return(
                                        <option key={index} value={c.code}>{c.code}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='w-full sm:w-2/3 mb-5 relative'>
                        <div className='form-field-wrap w-full no-field-wrap relative'>
                            <input 
                                type="tel" id="phone" placeholder="Mobile Number *" className='text-field'
                                {...register("phone",{required: {value:true,message:"Phone number is required"}})}
                            />                                
                        </div>
                    </div>
                </div>
                {errors.phonecode && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phonecode?.message}</p>}
                {errors.phone && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phone?.message}</p>}
                {questions.length > 0 &&
                <>
                    <div className="text-[#363F6C] font-medium italic border-y-[1px] border-slate-300 py-2.5 mb-5">Your Team has additionally requested...</div>
                    {questions.map((question)=>{
                        return(
                            <div className="question-item mb-5" key={question._id}>
                                <div className="mb-2.5 text-[14px]">{question.name}{question.required ? '*' :''}</div>
                                <div className='w-full relative'>
                                    <div className='form-field-wrap w-full no-field-wrap relative mb-5'>
                                        <textarea placeholder="Type your answer here!" rows={2} className='text-field !min-h-[50px]' {...register(`answers.${sanitizeKey(question.name)}`,{required: question.required ? {value:true, message:"This answer is required."} : false})}></textarea>
                                    </div>
                                </div>
                                {question.required && errors.answers?.[sanitizeKey(question.name)] && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.answers?.[sanitizeKey(question.name)].message}</p>}
                            </div>
                        )
                    })}
                </>
                }
                <div className='flex justify-center mb-10'>
                    <input className='button' type="submit" value="Confirm" />
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

export default UpdateDetailForm;