import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from "../Spinner/Spinner";
import { getWallet, getMyPaymentCards } from "../../libs/dataFunctions";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../libs/utils';
import CountriesList from "../../libs/countries";
import Amex from '../../assets/images/amex.svg';
import Discover from '../../assets/images/discover.svg';
import DinnersClub from '../../assets/images/dinersclub.svg';
import Jcb from '../../assets/images/jcb.svg';
import Mastercard from '../../assets/images/mastercard.svg';
import UnionPay from '../../assets/images/unionpay.svg';
import Visa from '../../assets/images/visa.svg';
import "./wallet.scss";
import "slick-carousel/slick/slick.css";

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const schema = yup.object({
    currency: yup.string().required('Currency is required.'),
    amount: yup.number().typeError('Enter Amount to add in wallet').min(2, 'Miniumum 2 required').required('Enter Amount to add in wallet'),
    card: yup.string().required('Card is required.'),
}).required();

const Wallet=({setShowForm, setShowWalletStatement})=>{
    const [loading,setLoading] = useState(false);
    const [concentLoading, setConcentLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);
    const [successMessage,setSuccessMessage] = useState(null);
    const [walletData, setWalletData] = useState([]);
    const [currencyArr, setCurrencyArr] = useState([]);
    const [autoPayment, setAutoPayment] = useState(false);

    const [currentCardID,setCurrentCardID]  = useState('');
    const [paymentCards,setPaymentCards] = useState([]);
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchWalletData=async()=>{
        setLoading(true);
        const [mywalletData, mycardsData] = await Promise.all([
            getWallet(token),
            getMyPaymentCards(token),
        ]);
        if(mywalletData.data && mywalletData.data.length > 0){
            setWalletData(mywalletData.data);
            var currArr=[];
            mywalletData.data.forEach((item)=>{
                currArr.push(item.currency);
            });
            setAutoPayment(mywalletData.allow_auto_payment);
            setCurrencyArr(currArr);
        }
        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
            /*mycardsData.data.forEach(element => {
                if(element.default_source){
                    setCurrentCardID(element.id);
                }
            });*/
        }
        setLoading(false);
    }

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        arrows:true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                }
            },
        ]
    };
    
    const onSubmit = async(data) => {
        setSuccessMessage(null);
        setErrorMessage(null);
        if(currentCardID !== '' && currentCardID !== 'new_card'){
            var formdata = new FormData();
            formdata.append('type','add_money');
            formdata.append('amount',data.amount * 100);
            formdata.append('card_id',currentCardID);
            formdata.append('currency',data.currency);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            setLoading(true);
            await fetch(process.env.REACT_APP_APIURL + '/app_wallet' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    setSuccessMessage(rdata.message);
                    if(rdata.data.redirct_url!==''){
                        window.location.href=rdata.data.redirct_url;
                    }
                }
                else{
                    setErrorMessage(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                setLoading(true);
                let formdata = new FormData();
                let expiry = cardExpiry.split("/");
                let today, cardday;
                today = new Date();
                cardday = new Date();
                cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
                if(cardicon==='amex'){
                    if(cardNum.replace(/\D+/g, '').length<15){
                        setErrorMessage("Invalid card number");
                        if(cardCVV.length<4){
                            setErrorMessage("Invalid card number, cvc number");
                        }
                        return;
                    }
                    if(cardCVV.length<4){
                        setErrorMessage("Invalid CVC number");
                        return;
                    }
                }
                else if(cardicon==='dinersclub'){
                    if(cardNum.replace(/\D+/g, '').length<14){
                        setErrorMessage("Invalid card number");
                        return;
                    }
                }
                else{
                    if(cardNum.replace(/\D+/g, '').length<16){
                        setErrorMessage("Invalid card number");
                        return;
                    }
                }
                if (cardday < today) {
                    setErrorMessage("Invalid expiry date");
                }
                else{
                    formdata.append('type','add_card');                    
                    formdata.append('card_number',cardNum);
                    formdata.append('name',cardHolderName);
                    formdata.append('exp_year',expiry[1]);
                    formdata.append('exp_month',expiry[0]);
                    formdata.append('country',country);
                    formdata.append('cvc',cardCVV);
                    formdata.append('is_default_card','Yes');

                    var secureHeader2 = new Headers();
                    secureHeader2.append('Authorization','Bearer ' + token);
                    secureHeader2.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader2
                    }
                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setLoading(false);
                            setErrorMessage(rdata.message);
                        }
                        else{
                            var formdata2 = new FormData();
                            formdata2.append('type','add_money');
                            formdata2.append('amount',data.amount * 100);
                            formdata2.append('card_id',rdata.data[0].id);
                            formdata2.append('currency',data.currency);

                            var secureHeader2 = new Headers();
                            secureHeader2.append('Authorization','Bearer ' + token);
                            secureHeader2.append('device_type','W');
                            const requestOptions2 = {
                                method: 'POST',
                                redirect: 'follow',
                                body: formdata2,
                                headers: secureHeader2
                            };
                            setLoading(true);
                            fetch(process.env.REACT_APP_APIURL + '/app_wallet' , requestOptions2)
                            .then(response2 => response2.text())
                            .then(result2 => {
                                setLoading(false);
                                let rdata2 = JSON.parse(result2);
                                if(rdata2.status===1){
                                    setSuccessMessage(rdata2.message);
                                    if(rdata2.data.redirct_url!==''){
                                        window.location.href=rdata2.data.redirct_url;
                                    }
                                }
                                else{
                                    setErrorMessage(rdata2.message);
                                }
                            })
                            .catch(error => console.log('error', error));
                        }
                    })
                    .catch(error => console.log('error', error));
                }            
            }
            else{
                setErrorMessage("Please fill all fields above.");
            }
        }
    }

    const updateConcent=async(e)=>{
        setConcentLoading(true);
        var formdata = new FormData();
        formdata.append('type','set_allow_auto_payment');
        if(e.target.checked){            
            formdata.append('allow_auto_payment',true);            
        }
        else{
            formdata.append('allow_auto_payment',false);
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_wallet' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setConcentLoading(false);
            let rdata = JSON.parse(result);
            setAutoPayment(rdata.allow_auto_payment);
        })
        .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        fetchWalletData();
    },[]);

    useEffect(()=>{
        if (walletData.length === 1) {
            setValue('currency', walletData[0].currency);
        }
    },[walletData]);

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-wallet relative">
                <div className="form-heading text-center mb-[30px]">My Wallet</div>
                {!loading ?
                <>
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    {walletData.length>0 ?
                        <>
                            {walletData.length===1 ?
                            <>
                                <div className="flex justify-center mb-5">
                                    <div className="text-center">
                                        <p className="text-[44px] leading-[30px] font-bold text-[var(--color-darkblue)] mb-2.5">{walletData[0].currency_symbol} {walletData[0].amount}</p>
                                        <span className="text-center text-sm text-[var(--color-darkblue)] mx-auto">Credit</span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="wallet-slider mb-5">
                                    <Slider {...settings}>
                                        {walletData.map((item)=>{
                                            return(
                                                <div key={item._id} className=" w-full rounded-[15px] bg-[#EFF1F9] py-5 px-4">
                                                    <span className="uppercase text-sm font-light text-[var(--color-darkblue)]">{item.currency}</span>
                                                    <p className="text-lg font-bold text-[var(--color-darkblue)]">{item.currency_symbol} {item.amount}</p>
                                                </div>
                                            )
                                        })}                                        
                                    </Slider>
                                </div>
                            </>
                            }
                            <div className="wallet-form px-5 py-5.5 md:px-7.5 mb-6">
                                <div className="mb-3.5 font-bold font-base text-[var(--color-darkblue)]">Add Money</div>
                                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
                                {walletData.length===1 ?
                                    <>
                                        <input type="hidden" {...register("currency")} /> 
                                        {errors.currency && <p className="error mt-2.5 mb-2.5" role="alert">{errors.currency?.message}</p>}
                                    </>
                                    :
                                    <div className="form-field-container mb-4">
                                        <div className='form-field-wrap amount-field-wrap relative'>
                                            <select 
                                                type="text" 
                                                id="currency" 
                                                disabled={loading} 
                                                className='select-field'
                                                {...register("currency")}
                                                aria-invalid={errors.currency ? "true" : "false"}  
                                                onChange={(e)=>{setValue('currency',e.target.value)}}                                              
                                            >
                                                <option value="">Select Currency *</option>
                                                {currencyArr.map((c)=>{
                                                    return(
                                                        <option key={c} value={c}>{c.toUpperCase()}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.currency && <p className="error mt-2.5 mb-2.5" role="alert">{errors.currency?.message}</p>}
                                    </div>
                                }
                                    <div className="form-field-container mb-4">
                                        <div className='form-field-wrap amount-field-wrap relative'>
                                            <input 
                                                type="text" 
                                                id="amount" 
                                                disabled={loading} 
                                                placeholder="Add Amount *" 
                                                className='text-field'
                                                {...register("amount")}
                                                aria-invalid={errors.amount ? "true" : "false"}                                                
                                            />
                                        </div>
                                        {errors.amount && <p className="error mt-2.5 mb-2.5" role="alert">{errors.amount?.message}</p>}
                                    </div>
                                    <div className="form-field-container mb-4">
                                        <div className='form-field-wrap w-full ccards-field-wrap relative mb-5'>
                                            <select {...register("card")} onChange={(e)=>{setCurrentCardID(e.target.value); setValue('card',e.target.value);}}>
                                                <option value="">Select Card *</option>
                                                {paymentCards.length > 0 && 
                                                <>
                                                {paymentCards.map((card) => {
                                                    return(
                                                        <option key={card.id} value={card.id}>****{card.last4} ({card.name})</option>
                                                    )
                                                })}
                                                </>
                                                }
                                                <option value={'new_card'}>Add New Card</option>
                                            </select>
                                        </div>
                                        {errors.card && <p className="error mt-2.5 mb-2.5" role="alert">{errors.card?.message}</p>}                                            
                                        {(paymentCards.length <= 0 || currentCardID==='new_card') &&
                                            <div className='flex flex-wrap'>
                                                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                    <input 
                                                        placeholder="0000 0000 0000 0000" className='text-field' 
                                                        type='tel'
                                                        name='number'
                                                        maxLength='19'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            e.target.value = formatCreditCardNumber(e.target.value);
                                                            setCardNum(e.target.value);
                                                            let icon = getIsUser(e.target.value);
                                                            setCardIcon(icon);
                                                            setErrorMessage(null);
                                                        }}
                                                    />
                                                    <div className='absolute top-[18px] right-[20px]'>
                                                        <CardIcon value={cardicon}/>
                                                    </div>
                                                </div>
                                                <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                                                    <input 
                                                        placeholder="MM/YY" className='text-field' 
                                                        type='tel'
                                                        name='expiry'
                                                        pattern='\d\d/\d\d'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            e.target.value = formatExpirationDate(e.target.value);
                                                            setCardExpiry(e.target.value);
                                                            setErrorMessage(null);
                                                        }}
                                                    />                                
                                                </div>
                                                <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pl-1 relative'>
                                                    <input 
                                                        type='tel'
                                                        name='cvc'
                                                        className='text-field'
                                                        placeholder='CVC'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            e.target.value = formatCVC(e.target.value, {number:cardNum});
                                                            setCardCVV(e.target.value);
                                                            setErrorMessage(null);
                                                        }}
                                                    />                                
                                                </div>
                                                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                    <input 
                                                        type='text'
                                                        name='name'
                                                        className='text-field'
                                                        placeholder='Card Holder Name'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            setCardName(e.target.value);
                                                            setErrorMessage(null);
                                                        }}
                                                    />                                
                                                </div>
                                                <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                                                    <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                                        <option value="">Select Country *</option>
                                                        {CountriesList.map((c,cindex)=>{
                                                            return(
                                                                <option key={cindex} value={c.code}>{c.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="flex items-center">
                                            {!concentLoading ?
                                                <>
                                                <input id="autoconcent" type="checkbox" className="appearance-none relative peer w-4 h-4 border border-[#CCD6DF] mr-2.5 shrink-0 checked:!bg-white" checked={autoPayment} onChange={(e)=>{updateConcent(e);}} />
                                                <label htmlFor="autoconcent" className="text-[11px] leading-[16.5px] font-light text-black cursor-pointer">
                                                    I consent to my sports team deducting fees from my wallet automatically when a payment is due.
                                                </label>
                                                <svg className="
                                                    absolute 
                                                    w-3 h-3 ml-0.5
                                                    hidden pointer-events-none peer-checked:block" 
                                                    width="7"
                                                    height="6" 
                                                    viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 3.00004L2.875 4.66671L6 1.33337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                </>
                                                :
                                                <>
                                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={16} height={16} alt="" />
                                                    <label className="text-[11px] leading-[16.5px] font-light text-black cursor-pointer">
                                                        I consent to my sports team deducting fees from my wallet automatically when a payment is due.
                                                    </label>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full px-2.5">
                                        <div className='flex justify-center'>
                                            <input className='button' type="submit" value="Confirm" disabled={loading}/>
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                                        {successMessage!=='' && <p className="text-green-500 mb-2.5 text-center" role="alert">{successMessage}</p>}
                                    </div>
                                </form>
                            </div>
                            <div className="flex justify-between gap-5.5">
                                <NavLink to={'/settings/saved_cards'} className="rounded-[16px] p-4 text-center bg-[var(--color-darkblue)] sm:w-[130px]" onClick={()=>{setShowForm(false)}}>
                                    <div className="w-8.5 h-8.5 rounded-[8px] bg-[var(--color-yellow)] mx-auto mb-4.5 flex justify-center items-center">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.1495 3.09021e-05C10.0264 0.00495398 9.90764 0.0271078 9.78394 0.0578771L1.41841 2.09603C0.428868 2.33849 -0.190209 3.33665 0.052868 4.3268L1.22579 9.11572C1.29687 9.40098 1.43526 9.66505 1.62937 9.88582C1.82349 10.1066 2.06767 10.2776 2.34148 10.3846V9.2308C2.34148 7.53419 3.72179 6.15388 5.41841 6.15388H13.1686L12.0147 1.40372C11.9127 0.990812 11.6713 0.625723 11.3314 0.37002C10.9916 0.114316 10.5739 -0.0163583 10.1489 3.09021e-05H10.1495ZM10.899 2.65418L11.3993 4.75018L9.32241 5.24988L8.80302 3.17295L10.899 2.65418ZM5.41841 7.38465C4.39933 7.38465 3.57225 8.21172 3.57225 9.2308V14.1539C3.57225 15.173 4.39933 16 5.41841 16H14.0338C15.0529 16 15.8799 15.173 15.8799 14.1539V9.2308C15.8799 8.21172 15.0529 7.38465 14.0338 7.38465H5.41841ZM5.41841 8.36557H14.0338C14.5083 8.36557 14.899 8.75634 14.899 9.2308V9.84618H4.55318V9.2308C4.55318 8.75634 4.94394 8.36557 5.41841 8.36557ZM4.55318 11.6923H14.899V14.1539C14.899 14.6283 14.5083 15.0191 14.0338 15.0191H5.41841C5.18933 15.0178 4.97001 14.9262 4.80802 14.7643C4.64604 14.6023 4.55447 14.383 4.55318 14.1539V11.6923Z" fill="#363F6C"/>
                                        </svg>
                                    </div>
                                    <div className="text-white font-medium font-sm hidden sm:block">Saved Cards</div>
                                </NavLink>
                                <NavLink to={'/settings/my_orders'} className="rounded-[16px] p-4 text-center bg-[var(--color-darkblue)] sm:w-[130px]" onClick={()=>{setShowForm(false)}}>
                                    <div className="w-8.5 h-8.5 rounded-[8px] bg-[var(--color-yellow)] mx-auto mb-4.5 flex justify-center items-center">
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.39941 15.5C6.08977 15.5 6.64941 14.9404 6.64941 14.25C6.64941 13.5596 6.08977 13 5.39941 13C4.70906 13 4.14941 13.5596 4.14941 14.25C4.14941 14.9404 4.70906 15.5 5.39941 15.5Z" fill="#363F6C"/>
                                            <path d="M11.8994 15.5C12.5898 15.5 13.1494 14.9404 13.1494 14.25C13.1494 13.5596 12.5898 13 11.8994 13C11.2091 13 10.6494 13.5596 10.6494 14.25C10.6494 14.9404 11.2091 15.5 11.8994 15.5Z" fill="#363F6C"/>
                                            <path d="M13.6497 6.25001C12.6551 6.25001 11.7013 5.85492 10.998 5.15166C10.2947 4.4484 9.89965 3.49457 9.89965 2.50001H3.25465L3.02965 1.78501C3.00494 1.70824 2.96202 1.63859 2.90455 1.582C2.84709 1.52541 2.77679 1.48355 2.69965 1.46001L0.649652 0.830013C0.586618 0.810643 0.520386 0.803878 0.454737 0.810105C0.389088 0.816332 0.325308 0.835428 0.267039 0.866303C0.14936 0.928659 0.0612718 1.03521 0.0221524 1.16251C-0.0169671 1.28982 -0.00391296 1.42745 0.0584429 1.54513C0.120799 1.66281 0.227349 1.75089 0.354652 1.79001L2.14965 2.34001L4.43965 9.57501L3.62465 10.245L3.55965 10.31C3.3581 10.5434 3.24391 10.8396 3.23661 11.1478C3.22932 11.4561 3.32937 11.7574 3.51965 12C3.65593 12.1657 3.82911 12.2973 4.02532 12.3841C4.22154 12.4709 4.43535 12.5106 4.64965 12.5H12.9947C13.1273 12.5 13.2544 12.4473 13.3482 12.3536C13.442 12.2598 13.4947 12.1326 13.4947 12C13.4947 11.8674 13.442 11.7402 13.3482 11.6465C13.2544 11.5527 13.1273 11.5 12.9947 11.5H4.56965C4.51208 11.498 4.45598 11.4813 4.40678 11.4513C4.35758 11.4213 4.31695 11.3792 4.2888 11.3289C4.26065 11.2786 4.24594 11.222 4.24609 11.1643C4.24624 11.1067 4.26124 11.0501 4.28965 11L5.49465 10H13.2097C13.3239 10.0033 13.4358 9.96745 13.5268 9.89831C13.6177 9.82917 13.6823 9.73095 13.7097 9.62001L14.5647 6.12001C14.2662 6.20096 13.9588 6.24462 13.6497 6.25001Z" fill="#363F6C"/>
                                            <path d="M13.6494 5C15.0301 5 16.1494 3.88071 16.1494 2.5C16.1494 1.11929 15.0301 0 13.6494 0C12.2687 0 11.1494 1.11929 11.1494 2.5C11.1494 3.88071 12.2687 5 13.6494 5Z" fill="#363F6C"/>
                                        </svg>
                                    </div>
                                    <div className="text-white font-medium font-sm hidden sm:block">My Purchases</div>
                                </NavLink>
                                <button className="rounded-[16px] p-4 text-center bg-[var(--color-darkblue)] sm:w-[130px]" onClick={()=>{setShowWalletStatement(true); setShowForm(false);}}>
                                    <div className="w-8.5 h-8.5 rounded-[8px] bg-[var(--color-yellow)] mx-auto mb-4.5 flex justify-center items-center">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.0001 14.6667C12.3537 14.6667 12.6928 14.5262 12.9429 14.2762C13.1929 14.0261 13.3334 13.687 13.3334 13.3334V5.33337L9.33342 1.33337H4.00008C3.64646 1.33337 3.30732 1.47385 3.05727 1.7239C2.80722 1.97395 2.66675 2.31309 2.66675 2.66671V13.3334C2.66675 13.687 2.80722 14.0261 3.05727 14.2762C3.30732 14.5262 3.64646 14.6667 4.00008 14.6667H12.0001ZM8.66675 2.66671L12.0001 6.00004H8.66675V2.66671ZM4.66675 5.33337H6.66675V6.66671H4.66675V5.33337ZM4.66675 8.00004H11.3334V9.33337H4.66675V8.00004ZM4.66675 10.6667H11.3334V12H4.66675V10.6667Z" fill="#363F6C"/>
                                        </svg>
                                    </div>
                                    <div className="text-white font-medium font-sm hidden sm:block">Wallet Statement</div>
                                </button>
                            </div>
                        </>
                        :
                        <><div className="text-center">Wallet Not Found.</div></>
                    } 
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
                }
            </div>
        </div>
        </>
    )
}

export default Wallet;