export async function loginUser(dispatch, loginPayload) {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: loginPayload,
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${process.env.REACT_APP_APIURL}/user_login`, requestOptions);
        let result = await response.text();
        let userData = JSON.parse(result);
        if (userData.status === 1) {
            const userEmail = userData?.data?.email;
            const userFirstName = userData?.data?.firstName;
            const userLastName = userData?.data?.lastName;
            const account_type = userData?.data?.accountType;
            const profilePic = userData?.data?.profilePicture;
            const userID = userData?.data?.user_id;
            const auth_key = userData?.data?.user_device?.auth_key;
            const refresh_token = userData?.data?.user_device?.refresh_token;
            
            let sessionData = {
                user:userEmail,
                FirstName: userFirstName,
                LastName: userLastName,
                account_type:account_type,
                profilePic:profilePic,
                userID:userID,
                sub: userData?.data?.sub,
                auth_key:auth_key,
                refresh_token:refresh_token,
                children: userData?.data?.children,
                dateOfBirth: userData?.data?.dateOfBirth,
                isAbove18: userData?.data?.is_above_18,
                isSubscriptionActive: userData?.data?.is_subscription_active,
                isVerify: userData?.data?.isVerify,
                phoneCode: userData?.data?.phoneCode,
                phoneNumber: userData?.data?.phoneNumber,
                superAdmin: userData?.data?.superAdmin ? userData?.data?.superAdmin : false
            }
            localStorage.setItem('isAuthenticated','true');
            localStorage.setItem('currentUser',JSON.stringify(sessionData));
            localStorage.setItem('auth_token',auth_key);
            
            dispatch({ type: 'LOGIN_SUCCESS', payload: userData?.data });
            
            return userData?.data;
        }

        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('auth_token');
        
        dispatch({ type: 'LOGIN_ERROR', error: userData.message });
        return userData;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function socialLoginUser(dispatch, loginPayload) {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: loginPayload,
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${process.env.REACT_APP_APIURL}/social_login`, requestOptions);
        let result = await response.text();
        let userData = JSON.parse(result);
        if (userData.status === 1) {
            const userEmail = userData?.data?.email;
            const userFirstName = userData?.data?.firstName;
            const userLastName = userData?.data?.lastName;
            const account_type = userData?.data?.accountType;
            const profilePic = userData?.data?.profilePicture;
            const userID = userData?.data?.user_id;
            const auth_key = userData?.data?.user_device?.auth_key;
            const refresh_token = userData?.data?.user_device?.refresh_token;
            
            let sessionData = {
                user:userEmail,
                FirstName: userFirstName,
                LastName: userLastName,
                account_type:account_type,
                profilePic:profilePic,
                userID:userID,
                sub: userData?.data?.sub,
                auth_key:auth_key,
                refresh_token:refresh_token,
                children: userData?.data?.children,
                dateOfBirth: userData?.data?.dateOfBirth,
                isAbove18: userData?.data?.is_above_18,
                isSubscriptionActive: userData?.data?.is_subscription_active,
                isVerify: userData?.data?.isVerify,
                phoneCode: userData?.data?.phoneCode,
                phoneNumber: userData?.data?.phoneNumber,
                superAdmin: userData?.data?.superAdmin ? userData?.data?.superAdmin : false
            }
            localStorage.setItem('isAuthenticated','true');
            localStorage.setItem('currentUser',JSON.stringify(sessionData));
            localStorage.setItem('auth_token',auth_key);
            
            dispatch({ type: 'LOGIN_SUCCESS', payload: userData?.data });
            
            return userData;
        }

        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('auth_token');
        
        dispatch({ type: 'LOGIN_ERROR', error: '' });
        return userData;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function updateUser(dispatch, updatePayload, secureHeader) {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: updatePayload,
        headers: secureHeader
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${process.env.REACT_APP_APIURL}/app_user`, requestOptions);
        let result = await response.text();
        let userData = JSON.parse(result);
        if (userData.status !== 0) {
            const userEmail = userData?.data?.email;
            const userFirstName = userData?.data?.firstName;
            const userLastName = userData?.data?.lastName;
            const account_type = userData?.data?.accountType;
            const profilePic = userData?.data?.profilePicture;
            const userID = userData?.data?.user_id;
            const auth_key = userData?.data?.user_device?.auth_key;
            const refresh_token = userData?.data?.user_device?.refresh_token;
            
            let sessionData = {
                user:userEmail,
                FirstName: userFirstName,
                LastName: userLastName,
                account_type:account_type,
                profilePic:profilePic,
                userID:userID,
                sub: userData?.data?.sub,
                auth_key:auth_key,
                refresh_token:refresh_token,
                children: userData?.data?.children,
                dateOfBirth: userData?.data?.dateOfBirth,
                isAbove18: userData?.data?.is_above_18,
                isSubscriptionActive: userData?.data?.is_subscription_active,
                isVerify: userData?.data?.isVerify,
                phoneCode: userData?.data?.phoneCode,
                phoneNumber: userData?.data?.phoneNumber,
                superAdmin: userData?.data?.superAdmin ? userData?.data?.superAdmin : false
            }

            localStorage.setItem('isAuthenticated','true');
            localStorage.setItem('currentUser',JSON.stringify(sessionData));
            localStorage.setItem('auth_token',auth_key);
            
            dispatch({ type: 'PROFILE_UPDATE', payload: userData?.data });
            
            return userData?.data;
        }

        dispatch({ type: 'LOGIN_ERROR', error: userData.message });
        return userData;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function updateMemberUser(dispatch, userData) {
    try {
        if (userData.user.length > 0) {
            const userEmail = userData.user.user;
            const userFirstName = userData.user.FirstName;
            const userLastName = userData.user.LastName;
            const account_type = userData.user.account_type;
            const profilePic = userData.user.profilePic;
            const userID = userData.user.userID;
            const auth_key = userData.user.auth_key;
            const refresh_token = userData.user.refresh_token;
            
            let sessionData = {
                user:userEmail,
                FirstName: userFirstName,
                LastName: userLastName,
                account_type:account_type,
                profilePic:profilePic,
                userID:userID,
                sub: userData?.data?.sub,
                auth_key:auth_key,
                refresh_token:refresh_token,
                children: userData?.data?.children,
                dateOfBirth: userData?.data?.dateOfBirth,
                isAbove18: userData?.data?.is_above_18,
                isSubscriptionActive: userData?.data?.is_subscription_active,
                isVerify: userData?.data?.isVerify,
                phoneCode: userData?.data?.phoneCode,
                phoneNumber: userData?.data?.phoneNumber,
                superAdmin: userData?.data?.superAdmin ? userData?.data?.superAdmin : false
            }

            localStorage.setItem('currentUser',JSON.stringify(sessionData));
            dispatch({ type: 'PROFILE_UPDATE', payload: userData.user });            
            return userData.user;
        }
        return userData.user;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function autologinUser(dispatch, loginPayload, secureHeader) {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: loginPayload,
        headers: secureHeader
    };

    try {
        dispatch({ type: 'REQUEST_AUTO_LOGIN' });
        let response = await fetch(`${process.env.REACT_APP_APIURL}/autologin`, requestOptions);
        let result = await response.text();
        let userData = JSON.parse(result);
        if (userData.status !== 0) {
            const userEmail = userData?.data?.email;
            const userFirstName = userData?.data?.firstName;
            const userLastName = userData?.data?.lastName;
            const account_type = userData?.data?.accountType;
            const profilePic = userData?.data?.profilePicture;
            const userID = userData?.data?.user_id;
            const auth_key = userData?.data?.user_device?.auth_key;
            const refresh_token = userData?.data?.user_device?.refresh_token;
            
            let sessionData = {
                user:userEmail,
                FirstName: userFirstName,
                LastName: userLastName,
                account_type:account_type,
                profilePic:profilePic,
                userID:userID,
                sub: userData?.data?.sub,
                auth_key:auth_key,
                refresh_token:refresh_token,
                children: userData?.data?.children,
                dateOfBirth: userData?.data?.dateOfBirth,
                isAbove18: userData?.data?.is_above_18,
                isSubscriptionActive: userData?.data?.is_subscription_active,
                isVerify: userData?.data?.isVerify,
                phoneCode: userData?.data?.phoneCode,
                phoneNumber: userData?.data?.phoneNumber,
                superAdmin: userData?.data?.superAdmin ? userData?.data?.superAdmin : false
            }

            localStorage.setItem('isAuthenticated','true');
            localStorage.setItem('currentUser',JSON.stringify(sessionData));
            localStorage.setItem('auth_token',auth_key);
            
            dispatch({ type: 'LOGIN_SUCCESS', payload: userData?.data });
            
            return userData?.data;
        }
        
        dispatch({ type: 'LOGIN_ERROR', error: userData.message });
        return userData;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function updateAdminRequest(dispatch, updatePayload, secureHeader) {
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: updatePayload,
        headers: secureHeader
    };

    try {
        //dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${process.env.REACT_APP_APIURL}/app_members`, requestOptions);
        let result = await response.text();
        let userData = JSON.parse(result);
        
        if (userData.status !== 0) {
            const userEmail = userData?.data?.email;
            const userFirstName = userData?.data?.firstName;
            const userLastName = userData?.data?.lastName;
            const account_type = userData?.data?.accountType;
            const profilePic = userData?.data?.profilePicture;
            const userID = userData?.data?.user_id;
            const auth_key = userData?.data?.user_device?.auth_key;
            const refresh_token = userData?.data?.user_device?.refresh_token;
            
            let sessionData = {
                user:userEmail,
                FirstName: userFirstName,
                LastName: userLastName,
                account_type:account_type,
                profilePic:profilePic,
                userID:userID,
                sub: userData?.data?.sub,
                auth_key:auth_key,
                refresh_token:refresh_token,
                children: userData?.data?.children,
                dateOfBirth: userData?.data?.dateOfBirth,
                isAbove18: userData?.data?.is_above_18,
                isSubscriptionActive: userData?.data?.is_subscription_active,
                isVerify: userData?.data?.isVerify,
                phoneCode: userData?.data?.phoneCode,
                phoneNumber: userData?.data?.phoneNumber,
                superAdmin: userData?.data?.superAdmin ? userData?.data?.superAdmin : false
            }

            localStorage.setItem('isAuthenticated','true');
            localStorage.setItem('currentUser',JSON.stringify(sessionData));
            localStorage.setItem('auth_token',auth_key);
            
            dispatch({ type: 'PROFILE_UPDATE', payload: userData?.data });
            
            return userData?.data;
        }

        dispatch({ type: 'LOGIN_ERROR', error: userData.message });
        return userData;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}
   
export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('auth_token');
    localStorage.clear();
}