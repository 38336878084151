import {useState, useEffect} from 'react';
import { useFormData } from '../../contexts/FormContext';
import Spinner from '../../components/Spinner/Spinner';
import { browserName, browserVersion } from "react-device-detect";

const SignUpCompleted = () => {
  const { data } = useFormData();
  const [loading, setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const submitSignup = async ()=>{
    setLoading(true);
    let formdata = new FormData();
    formdata.append('firstName',data.first_name);
    formdata.append('lastName',data.last_name);
    formdata.append('email',data.email);
    formdata.append('country',data.country);
    formdata.append('dateOfBirth',data.birthdate);
    formdata.append('is_above_18',data.is_above_18);
    formdata.append('phoneCode',data.phonecode);
    formdata.append('phoneNumber',data.phone);
    formdata.append("opt_given", data.opt_given ? 'Yes' : 'No');
    /*formdata.append('address',data.location);
    formdata.append('latitude',data.lattitude);
    formdata.append('longitude',data.longitude);*/
    if(data.children!==undefined){
      formdata.append('childrens',JSON.stringify(data.children));
    }
    formdata.append('gender',data.gender);
    formdata.append('sports_id',data.sports.join());
    formdata.append("device_type", "W");
    formdata.append("device_token", "1234");
    formdata.append("device_unique_id", "123");
    formdata.append("device_name", browserName);
    formdata.append("app_version", "2.9.5");
    formdata.append("os", browserVersion);
    if(data.social_id!==undefined){
      formdata.append("registered_type",data.registered_type);
      formdata.append("social_id",data.social_id);
      
    }
    else{
      formdata.append('password',data.password);
      formdata.append("registered_type","app");
      formdata.append("social_id","");
    }    
    var secureHeader = new Headers();
    secureHeader.append('device_type','W');
    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: formdata, 
        headers: secureHeader
    };
    /*for (var pair of formdata.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }*/
    await fetch(process.env.REACT_APP_APIURL + '/user_signup' , requestOptions)
    .then(response => response.text())
    .then(result => {
        setLoading(false);
        let rdata = JSON.parse(result);
        
        if(rdata.status !== 1){
            setErrorMessage(rdata.message);
            setSuccessMessage('');
        }
        else{
            setErrorMessage('');
            setSuccessMessage(rdata.message);
        }
    })
    .catch(error => console.log('error', error));
    setLoading(false);
  }
  useEffect(()=>{
    submitSignup();
  },[])
  return (
    !loading ?
    <>
      {errorMessage!=='' ?
        <p className='error text-center mb-2.5'>{errorMessage}</p>
      :
        <h2 className='text-center'>{successMessage!=='' ? successMessage : 'Verify your account, an email has been sent to your inbox.'}</h2>
      }
      <div className='auth-form-field-wrap flex justify-center'>
          <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
      </div>
    </>
    :
    <>
      <div className="py-5 text-center"><Spinner /></div>
    </>
  );
}
export default SignUpCompleted;