import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
//import imageCompression from 'browser-image-compression';
import { confirmAlert } from "react-confirm-alert";
import { useAuthState } from "../../../contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "../../../components/Spinner/Spinner";
import AndroidIcon from '../../../assets/images/icon-android.svg';
import IosIcon from '../../../assets/images/icon-ios.svg';
import webIcon from '../../../assets/images/icon-web.svg';
import ReactDatePicker from 'react-datepicker';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
dayjs.extend(utc);
dayjs.extend(tz);

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const schema = yup.object({
    fullname:yup.string().required('Full name is required.'),
    club_name:yup.string().required('Full name is required.'),
    issue_severity:yup.string().required('select Issue Severity Option.'),
    bug_location_page:yup.string().required('Bug Location/Page is required.'),
    report_date: yup.string().required('Please select Report Date.'),
    description: yup.string().required('Description is required.'),
}).required();

const ReportIssue=()=>{   
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    
    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const userDetails = useAuthState();
    
    const successPrompt=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5 uppercase">Your Report Has Been Received!</p>
                    <p className="text-center text-[14px] leading-[18px] mb-5">A member of the team will be in touch really soon to provide the additional support that you need.</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={()=>{
                            onClose();
                            window.location='/settings/report_issue';
                        }}>Ok</button>
                    </div>
                </div>
                );
            }
        });
    }

    const onSubmit=async(data)=>{
        setErrorMessage(null);
        setSuccessMessage(null);
        setLoading(true);
        if(images.length > 0){  
            let imageformdata = new FormData();          
            //uplaoded without compression
            for(let i=0; i<images.length; i++){
                imageformdata.append("files[]", images[i].file);               
            };

            var requestOptions = {
                method: 'PUT',
                body: imageformdata,
                redirect: 'follow'
            };
            await fetch("https://dashboard.plaisport.com/upload_content_v2", requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    if(rdata.data?.images!==undefined && rdata.data?.images.length > 0 ){
                        let formdata = new FormData();
                        formdata.append('type','bug_report');                        
                        formdata.append('name',data.fullname);
                        formdata.append('club_name',data.club_name);
                        formdata.append('device',data.device_type);
                        formdata.append('issue_severity',data.issue_severity);
                        formdata.append('bug_location_page',data.bug_location_page);
                        formdata.append('bug_location_url',data.bug_location_page);
                        formdata.append('date',dayjs(data.report_date).format("YYYY-MM-DD"));
                        formdata.append('description', data.description);
                        formdata.append('files',rdata.data.images.join('@#'));
                        formdata.append('separate_by','@#');

                        var secureHeader = new Headers();
                        secureHeader.append('Authorization','Bearer ' + token);
                        secureHeader.append('device_type','W');
                        const requestOptions = {
                            method: 'POST',
                            redirect: 'follow',
                            body: formdata,
                            headers: secureHeader
                        };
                        fetch(process.env.REACT_APP_APIURL + '/addfeedback' , requestOptions)
                        .then(response => response.text())
                        .then(result2 => {
                            setLoading(false);
                            let rdata2 = JSON.parse(result2);
                            if(rdata2.status !== 1){
                                setErrorMessage(rdata2.message);
                            }
                            else{
                                reset();
                                //setSuccessMessage(rdata2.message);
                                successPrompt();
                            }
                        })
                        .catch(error => console.log('error', error));
                    }
                    else{
                        setLoading(false);
                        setErrorMessage("Image not uploaded due to some technical issue, try again");
                    }
                }
                else{
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
            });
        }
        else{
            let formdata = new FormData();
            formdata.append('type','bug_report');                        
            formdata.append('name',data.fullname);
            formdata.append('club_name',data.club_name);
            formdata.append('device',data.device_type);
            formdata.append('issue_severity',data.issue_severity);
            formdata.append('bug_location_page',data.bug_location_page);
            formdata.append('bug_location_url',data.bug_location_page);
            formdata.append('date',dayjs(data.report_date).format("YYYY-MM-DD"));
            formdata.append('description', data.description);
            formdata.append('separate_by','@#');
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/addfeedback' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setErrorMessage(rdata.message);
                }
                else{
                    reset();
                    //setSuccessMessage(rdata.message);
                    successPrompt();
                }
            })
            .catch(error => console.log('error', error));
        }
    }

    useEffect(()=>{
        setValue('device_type','W');
        setValue('fullname',userDetails?.user?.FirstName+' '+userDetails?.user?.LastName);        
    },[]);

    return(
        !loading ?
        <>
            <div className="myteams_section">
                <div className="myteams_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5 uppercase">Bug Report Form</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>                    
                </div>
                <div className="issue-form">
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                        <div className="flex flex-wrap w-full">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Full Name*:</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="full-name" disabled={loading} placeholder="Full Name" className='text-field'
                                        {...register("fullname")}
                                        aria-invalid={errors.fullname ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.fullname && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.fullname?.message}</p>}
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">My Teams*:</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="my-teams" disabled={loading} placeholder="My Teams*" className='text-field'
                                        {...register("club_name")}
                                        aria-invalid={errors.club_name ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.club_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.club_name?.message}</p>}
                            </div>
                        </div>
                        <div className="w-full md:w-2/3 lg:w-3/4 xl:w-1/2 md:px-2.5">
                            <div className="font-light text-black leading-[24px] text-[16px]">Platform*</div>
                            <div className='mb-6 button-group flex flex-wrap relative'>
                                <div className="w-full sm:w-1/3 sm:pr-2.5">
                                    <input type="radio" {...register("device_type")} value="W" id="devicetype-w" className="peer hidden" />
                                    <label
                                        htmlFor="devicetype-w"
                                        className="flex items-center justify-center text-[18px] leading-[22px] font-medium w-full h-[54px] py-[5px] cursor-pointer select-none rounded-lg bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >
                                            <img src={webIcon} alt="" width={19} height={22} className="mr-3.5" />Web
                                        </label>
                                </div>
                                <div className="w-full sm:w-1/3 sm:pr-2.5">
                                    <input type="radio" {...register("device_type")} value="I" id="devicetype-i" className="peer hidden" />
                                    <label
                                        htmlFor="devicetype-i"
                                        className="flex items-center justify-center text-[18px] leading-[22px] font-medium w-full h-[54px] py-[5px] cursor-pointer select-none rounded-lg bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >
                                            <img src={IosIcon} alt="" width={19} height={22} className="mr-3.5" />iOS
                                        </label>
                                </div>
                                <div className="w-full sm:w-1/3">
                                    <input type="radio" {...register("device_type")} value="A" id="devicetype-a" className="peer hidden" />
                                    <label
                                        htmlFor="devicetype-a"
                                        className="flex items-center justify-center text-[18px] leading-[22px] font-medium w-full h-[54px] py-[5px] cursor-pointer select-none rounded-lg bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >
                                            <img src={AndroidIcon} alt="" width={19} height={22} className="mr-3.5" />Android
                                        </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Report Date* (DD MMM YYYY)</div>
                                <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                    <Controller
                                        control={control}
                                        name='report_date'
                                        errors={errors}
                                        render={({ field, ref }) => (
                                            <ReactDatePicker
                                                placeholderText='Report Date*'
                                                minDate={new Date()}
                                                onChange={(date) => {field.onChange(date)}}
                                                selected={field.value}
                                                mask="____-__-__"
                                                dateFormat="dd MMM yyyy"
                                                inputRef={ref}
                                            />
                                        )}
                                    />
                                </div>
                                {errors.report_date && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.report_date?.message}</p>}
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Issue Severity*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <select {...register("issue_severity")} onChange={(e)=>setValue('issue_severity', e.target.value, { shouldValidate: true })}>
                                        <option value="LOW">LOW</option>
                                        <option value="MEDIUM">MEDIUM</option>
                                        <option value="HIGH">HIGH</option>
                                        <option value="SUPER HIGH">SUPER HIGH</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full mb-5">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Bug location / page*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="buglocation" disabled={loading} placeholder="Bug location / page*" className='text-field'
                                        {...register("bug_location_page")}
                                        aria-invalid={errors.bug_location_page ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.bug_location_page && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.bug_location_page?.message}</p>}
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Bug location URL</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="buglocation_url" disabled={loading} placeholder="Bug location URL" className='text-field'
                                        {...register("bug_location_url")}
                                        aria-invalid={errors.bug_location_url ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.bug_location_url && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.bug_location_url?.message}</p>}
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full mb-5">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Description*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative z-10'>
                                    <textarea disabled={loading} placeholder="Tell us more about your problem...." className='textarea-field'
                                        {...register("description")}
                                        rows={9}
                                        aria-invalid={errors.description ? "true" : "false"} 
                                    />
                                </div>
                                {errors.description && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.description?.message}</p>}
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-black leading-[24px] text-[16px]">Screenshot/Recording of the Issue</div>
                                <p className="text-[12px] font-light text-zinc-600">A screen recording helps us to know exactly what you experienced so that we can most quickly resolve it.</p>                            
                                <div className='form-field-wrap mb-5 relative'>
                                    <FilePond                                        
                                        files={images}
                                        onupdatefiles={setImages}
                                        allowMultiple={true}
                                        credits={false}
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'video/mp4','video/webm','video/ogg']}
                                        labelFileTypeNotAllowed='File of invalid type'
                                        allowImageTransform={true}
                                        labelIdle='
                                            <div class="upload-icon mx-auto"></div>
                                            <div class="upload-text">
                                                <h4>Select File</h4>
                                                <p class="text-center">Maximum upload file size: 2MB</p>
                                            </div>'
                                        {...register("imagess")}
                                    />
                                </div>
                            </div>
                        </div>                        
                        <div className="w-full flex flex-col items-center justify-center">
                            <p className="text-center font-light text-[12px] leading-[18px] mb-[25px]">Never submit your passwords here.<br/>Check out our <a href="https://plaisport.com/plai-privacy-policy/" target="_blank" rel="noreferrer" className="font-medium underline hover:no-underline">privacy policy</a>.</p>
                            <input className='button button--large mx-auto' type="submit" value="Submit" disabled={loading}/>
                        </div>
                        {errorMessage!=='' && <p className="text-red-500 mb-2.5 -mt-2.5" role="alert">{errorMessage}</p>}
                        {successMessage!=='' && <p className="text-green-600 mb-2.5 -mt-2.5" role="alert">{successMessage}</p>}
                    </form>
                </div>
            </div>
        </>
       :
       <>
           <div className="py-5 text-center"><Spinner /></div>
       </>
    );
}

export default ReportIssue;