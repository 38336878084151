import { useEffect, useState, useContext, useRef } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useAuthState } from '../../../contexts/AuthContext';
import AdminNavbar from '../../../components/AdminNavbar/AdminNavbar';
import { TeamContext, TeamContextProvider } from '../../../contexts/TeamContext';
import { getYourFormDetail, getMyPaymentCards, getYourFormDetailSubmitted, getJoinedChild, getWallet } from '../../../libs/dataFunctions';
import Spinner from '../../../components/Spinner/Spinner';
import tinyCalendarIcon from "../../../assets/images/icon-tiny-calendar.svg";
import tinyUserIcon from "../../../assets/images/icon-tiny-user.svg";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../../libs/utils';
import CountriesList from '../../../libs/countries';
import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/discover.svg';
import DinnersClub from '../../../assets/images/dinersclub.svg';
import Jcb from '../../../assets/images/jcb.svg';
import Mastercard from '../../../assets/images/mastercard.svg';
import UnionPay from '../../../assets/images/unionpay.svg';
import Visa from '../../../assets/images/visa.svg';
import shopIcon from '../../../assets/images/icon-shop-tiny.svg';
import InfoIcon from '../../../assets/images/info.svg';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import "./yourformresponse.scss";

dayjs.extend(utc);
dayjs.extend(tz);

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const ResponseFormView=({formId})=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [formItem, setFormItem] = useState([]);
    const userDetailAuth = useAuthState();
    const userDetail = userDetailAuth?.user;

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchFormdata = async()=> {
        setLoading(true);
        const [formData] = await Promise.all([
            getYourFormDetail(team._id, token, formId, userDetail.sub),
        ]);
        setFormItem(formData.data);        
        setLoading(false);
    };

    useEffect(() => {
        if (!teamUpdating) {
            fetchFormdata();
        }
    }, [teamUpdating]);

    return (
        <>
        {!loading && !teamUpdating ? 
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/your-forms`}>
                    Forms
                </NavLink>
                <span className='separator'>&lt;</span>
                {formItem.title}
            </div>
            <div className='body'>
                <div className='p-7.5'>
                    {formItem.submited_at!=="" ?
                    <FormFillView sformID={formItem.submited_from_id} token={token} team={team} />
                    :
                    <FormFillSubmit formItem={formItem} token={token} team={team} />
                    }
                </div>
            </div>            
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}
const FormFillView=({sformID, token, team})=>{
    const [loading, setLoading] = useState(false);
    const [sformItem, setSFormItem] = useState([]);
    const fetchFormdata = async()=> {
        setLoading(true);
        const [formData] = await Promise.all([
            getYourFormDetailSubmitted(team._id, token, sformID)
        ]);
        setSFormItem(formData.data);
        setLoading(false);
    };
    
    useEffect(() => {
        fetchFormdata();
    }, []);

    return(
        <>
        {!loading ? 
            <>
                <div className="form-detail-card bg-white py-6 px-5 lg:px-9.5 relative">
                    <div className='flex flex-col md:flex-row md:gap-5 justify-between items-start'>
                        <div>
                            <h2 className="form-card-title font-bold text-lg/6 mb-4.5">{sformItem.title}</h2>
                            <div className="tracking-[0.01em] font-light text-xs mb-5 md:mb-7">{sformItem.description}</div>
                        </div>
                        <div className='mb-7 md:mb-0'>
                            {sformItem.submited_at!=="" ?
                            <>
                            <div className="completed-status">
                                <div className="flex-1">
                                    <div className="tracking-[0.01em] text-xs">Completed on</div>
                                    <div className="tracking-[0.01em] text-sm font-semibold">{dayjs.utc(sformItem.submited_at).local().format("DD MMM YYYY | hh:mm A")}</div>
                                </div>
                            </div>
                            </>
                            :
                            <>
                            <div className="notstarted-status text-xs font-medium">Incomplete</div>
                            </>
                            }
                        </div>
                    </div>                        
                    <div className="flex flex-col sm:flex-row gap-5 lg:gap-12.5">
                        <div className="flex items-center">
                            <img src={tinyCalendarIcon} className="w-4 h-4 mr-1.5" alt=""/>
                            <div className="flex flex-1">
                                <div>
                                    <div className="tracking-[0.01em] text-xs">Created on</div>
                                    <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(sformItem.created_at).format("DD MMM YYYY")}</div>
                                </div>
                                {sformItem.expire_date!=="" &&
                                    <>
                                    <svg className="mx-4" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.5 1L0.499999 35.641" stroke="#363F6C" strokeWidth="0.5"/>
                                    </svg>
                                    <div>
                                        <div className="tracking-[0.01em] text-xs">Expires</div>
                                        <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(sformItem.expire_date).format("DD MMM YYYY")}</div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                        {sformItem.create_user!=="" &&
                        <div className="flex items-center">
                            <img src={tinyUserIcon} className="w-4 h-4 mr-1.5" alt=""/>
                            <div className="flex-1">
                                <div className="tracking-[0.01em] text-xs">Created by</div>
                                <div className="tracking-[0.01em] text-sm font-semibold">{sformItem.create_user}</div>
                            </div>
                        </div>
                        }
                    </div>
                    <hr className='my-8'/>
                    <div className='questions'>
                        <div className='form-wrapper'>
                        {sformItem.questions!==undefined &&
                            <>
                            {sformItem.questions.map((question, index)=>{
                                return(
                                    <div key={question._id} className='question-box mb-7.5'>
                                        <div className='question-index text-xs text-white inline-block px-4 py-1 mb-4'>Question {index+1}</div>
                                        <div className='px-4 pb-5 font-bold text-base tracking-[0.5px]'>
                                            {question.question}{question.is_required.toLowerCase()==='yes' && <sup>*</sup>}
                                        </div>
                                        <div className='relative px-4 pb-5'>
                                            {question.question_type==='paragraph' &&
                                            <>
                                                <textarea readOnly className='w-full text-base !bg-white px-5 py-2.5 rounded-[8px] outline-none' defaultValue={question.answer_single}/>
                                            </>
                                            }
                                            {question.question_type==='short_answer' &&
                                                <>
                                                <input readOnly className='w-full text-base !bg-white px-5 py-2.5 rounded-[8px] outline-none' defaultValue={question.answer_single}/>
                                                </>
                                            }
                                            {question.question_type==='checkbox' &&
                                                <>
                                                <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                {question.options.map((checkOption, oindex)=>{
                                                    return(
                                                        <label htmlFor={question._id+'-'+oindex} key={oindex} className='flex gap-2.5 items-center'>
                                                            <input 
                                                                id={question._id+'-'+oindex}
                                                                type="checkbox"
                                                                value={checkOption}
                                                                readOnly
                                                                className='appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer' 
                                                                checked={question.answer_multiple.includes(checkOption) ? true : false}
                                                            />
                                                            <span className='text-xs/3 font-light'>{checkOption}</span>
                                                        </label>
                                                    )
                                                })}                                                        
                                                </div>
                                                </>
                                            }
                                            {question.question_type==='single_selection' &&
                                                <>
                                                <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                    {question.options.map((checkOption, oindex)=>{
                                                        return(
                                                            <label htmlFor={question._id+'-'+oindex} key={oindex} className='flex gap-2.5 items-center'>
                                                                <input 
                                                                    id={question._id+'-'+oindex}
                                                                    type="radio"
                                                                    value={checkOption}
                                                                    readOnly
                                                                    className='h-3 w-3 border border-[#363F6C] bg-white align-top float-left mr-1 cursor-pointer' 
                                                                    checked={question.answer_single===checkOption ? true : false}
                                                                />
                                                                <span className='text-xs/3 font-light'>{checkOption}</span>
                                                            </label>
                                                        )
                                                    })}                                                        
                                                </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}                                
                            </>                            
                        }
                        </div>
                    </div>
                </div>
            </>
            :
            <><div className="py-5 text-center"><Spinner /></div></>
        }
        </>
    )
}
const FormFillSubmit=({formItem, token, team})=>{
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);    
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');
    const [paymentCards,setPaymentCards] = useState([]);
    const [currentCardID,setCurrentCardID]  = useState('');
    const userDetailAuth = useAuthState();
    const userDetail = userDetailAuth?.user;
    const { register, control, handleSubmit, watch, formState: { errors } } = useForm();

    const [payInInstallment, setPayInInstallment] = useState(false);
    const [showInstallmentDetails, setShowInstallmentDetails] = useState(false);
    const [singleBuyEmi, setSingleBuyEmi] = useState(null);

    const promoRef = useRef();

    const [walletData, setWalletData] = useState([]);
    const [isWallet, setWallet] = useState(false);
    const [walletTransactionFee, setWalletTransactionFee] = useState(0);
    const [isCardPayment, setIsCardPayment] = useState(false);

    const [promocode, setPromocode] = useState('');
    const [promoloading, setPromoLoading] = useState(false);
    const [promoCodeError, setPromoCodeerror] = useState(null);
    const [promoCodeSuccess, setPromoCodeSuccess] = useState(false);
    const [promoDiscountAmount, setPromoDiscountAmount] = useState(0);
    const [selectedSize,setSelectedSize] = useState('');
    const [selectedSleeve,setSelectedSleeve] = useState('');
    const [selectedColor,setSelectedColor] = useState('');
    const [selectedGender,setSelectedGender] = useState('');
    const [shirtName,setShirtName] = useState('');
    const [shirtNumber,setShirtNumber] = useState('');
    const [errorState, setErrorState] = useState({sizeError:false, sleeveError:false, colorError:false, genderError:false, shirtNumberError:false, shirtNameError:false});

    const [joinedChild,setJoinedChild] = useState([]);

    const noPaymentForm=async(data)=>{
        setSuccessMessage(null);
        setErrorMessage(null);
        var answers = [];
        var formdata = new FormData();
        formdata.append('type','submit_from');
        formdata.append('team_id',team._id);
        formdata.append('form_id',formItem._id);
        formdata.append('sub',data.joinchild_sub);
        data.answers.forEach((dataItem)=>{
            let tmpAns = {}; 
            if(dataItem.answer!==undefined){
                switch(dataItem.question_type){
                    case 'checkbox':
                        if(dataItem.answer!==false){
                            tmpAns.question_id=dataItem.question;
                            tmpAns.answer = dataItem.answer.join('@#');
                        }                        
                        break;
                    default:
                        tmpAns.question_id=dataItem.question;
                        tmpAns.answer = dataItem.answer;
                }
                if(Object.keys(tmpAns).length>0){
                    answers.push(tmpAns);
                }
            }
        });

        formdata.append('answers',JSON.stringify(answers));
        formdata.append('separate_by','@#');
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status!==1){
                setErrorMessage(rdata.message);
            }
            else{
                window.location.href = '/team/'+team._id+'/your-forms';
            }
        })
        .catch(error => console.log('error', error));
    }

    const withPaymentForm=async(data)=>{
        setSuccessMessage(null);
        setErrorMessage(null);
        
        if(formItem?.product_details!==null && Object.keys(formItem?.product_details).length>0){
            let newState={sizeError:false, sleeveError:false, colorError:false, genderError:false, shirtNumberError:false, shirtNameError:false};
            let hasError = false;
            if(formItem?.product_details?.is_personalize){
                if(shirtNumber===''){
                    hasError=true;
                    newState.shirtNumberError=true;
                }
                if(shirtName===''){
                    hasError=true;
                    newState.shirtNameError=true;
                }
            }
            if(formItem?.product_details?.size!==undefined && formItem?.product_details?.size.length > 0){
                if(selectedSize===''){
                    hasError=true;
                    newState.sizeError=true;
                }
            }
            if(formItem?.product_details?.sleeve!==undefined && formItem?.product_details?.sleeve.length > 0){
                if(selectedSleeve===''){
                    hasError=true;
                    newState.sleeveError=true;
                }
            }
            if(formItem?.product_details?.color!==undefined && formItem?.product_details?.color.length > 0){
                if(selectedColor===''){
                    hasError=true;
                    newState.colorError=true;
                }
            }
            if(formItem?.product_details?.gender!==undefined && formItem?.product_details?.gender.length > 0){
                if(selectedGender===''){
                    hasError=true;
                    newState.genderError = true;
                }
            }        
            setErrorState(newState);
            if(hasError){                
                setErrorMessage("There are some error, please check form again.");
                return;
            }
        }
        if(isWallet && !isCardPayment){
            setLoading(true);
            var answers = [];
            var formdata = new FormData();
            formdata.append('type','submit_from');
            formdata.append('team_id',team._id);
            formdata.append('form_id',formItem._id);
            formdata.append('sub',data.joinchild_sub);
            formdata.append('card_id',"");
            formdata.append('use_wallet',isWallet);
            formdata.append('purchase_in_installment',payInInstallment ? 'Yes' : 'No');
            if(promoCodeSuccess && promocode!==''){
                formdata.append("promocode",promocode);
            }
            data.answers.forEach((dataItem)=>{
                let tmpAns = {}; 
                if(dataItem.answer!==undefined){
                    switch(dataItem.question_type){
                        case 'checkbox':
                            if(dataItem.answer!==false){
                                tmpAns.question_id=dataItem.question;
                                tmpAns.answer = dataItem.answer.join('@#');
                            }
                            break;
                        default:
                            tmpAns.question_id=dataItem.question;
                            tmpAns.answer = dataItem.answer;
                    }
                    if(Object.keys(tmpAns).length>0){
                        answers.push(tmpAns);
                    }
                }
            });
            formdata.append('answers',JSON.stringify(answers));
            formdata.append('separate_by','@#');

            if(formItem?.product_details!==null && Object.keys(formItem?.product_details).length>0){
                if(formItem?.product_details?.is_personalize){
                    if(shirtNumber!==''){
                        formdata.append('shirt_no', shirtNumber);
                    }
                    if(shirtName!==''){
                        formdata.append('shirt_name', shirtName);
                    }
                }
                if(formItem?.product_details?.size!==undefined && formItem?.product_details?.size.length > 0 && selectedSize!==''){
                    formdata.append('size',selectedSize);
                }
                if(formItem?.product_details?.sleeve!==undefined && formItem?.product_details?.sleeve.length > 0 && selectedSleeve!==''){
                    formdata.append('sleeve',selectedSleeve);
                }
                if(formItem?.product_details?.color!==undefined && formItem?.product_details?.color.length > 0 && selectedColor!==''){
                    formdata.append('color',selectedColor);
                }
                if(formItem?.product_details?.gender!==undefined && formItem?.product_details?.gender.length > 0 && selectedGender!==''){
                    formdata.append('gender',selectedGender);
                }
            }

            setLoading(true);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status!==1 && rdata.status!==4){
                    setErrorMessage(rdata.message);
                }
                else if(rdata.status===4){
                    window.location.href='/thankyou';
                }
                else{
                    //window.location.href = '/team/'+team._id+'/your-forms';
                    if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                        ///Go to thank you page
                        window.location.href='/thankyou';
                    }
                    else{
                        window.location.href=rdata.data.redirct_url;
                    }
                }
            })
            .catch(error => console.log('error', error));
        }
        else if(isWallet || isCardPayment){
            if(currentCardID!==''){
                setLoading(true);
                var answers = [];
                var formdata = new FormData();
                formdata.append('type','submit_from');
                formdata.append('team_id',team._id);
                formdata.append('form_id',formItem._id);
                formdata.append('sub',data.joinchild_sub);
                if(isCardPayment){
                    formdata.append('card_id',currentCardID);
                    formdata.append('use_wallet',isWallet);                
                }
                else{
                    formdata.append('card_id',"");
                    formdata.append('use_wallet',isWallet);                
                }
                formdata.append('purchase_in_installment',payInInstallment ? 'Yes' : 'No');
                if(promoCodeSuccess && promocode!==''){
                    formdata.append("promocode",promocode);
                }
                data.answers.forEach((dataItem)=>{
                    let tmpAns = {}; 
                    if(dataItem.answer!==undefined){
                        switch(dataItem.question_type){
                            case 'checkbox':
                                if(dataItem.answer!==false){
                                    tmpAns.question_id=dataItem.question;
                                    tmpAns.answer = dataItem.answer.join('@#');
                                }
                                break;
                            default:
                                tmpAns.question_id=dataItem.question;
                                tmpAns.answer = dataItem.answer;
                        }
                        if(Object.keys(tmpAns).length>0){
                            answers.push(tmpAns);
                        }
                    }
                });
                formdata.append('answers',JSON.stringify(answers));
                formdata.append('separate_by','@#');

                if(formItem?.product_details!==null && Object.keys(formItem?.product_details).length>0){
                    if(formItem?.product_details?.is_personalize){
                        if(shirtNumber!==''){
                            formdata.append('shirt_no', shirtNumber);
                        }
                        if(shirtName!==''){
                            formdata.append('shirt_name', shirtName);
                        }
                    }
                    if(formItem?.product_details?.size!==undefined && formItem?.product_details?.size.length > 0 && selectedSize!==''){
                        formdata.append('size',selectedSize);
                    }
                    if(formItem?.product_details?.sleeve!==undefined && formItem?.product_details?.sleeve.length > 0 && selectedSleeve!==''){
                        formdata.append('sleeve',selectedSleeve);
                    }
                    if(formItem?.product_details?.color!==undefined && formItem?.product_details?.color.length > 0 && selectedColor!==''){
                        formdata.append('color',selectedColor);
                    }
                    if(formItem?.product_details?.gender!==undefined && formItem?.product_details?.gender.length > 0 && selectedGender!==''){
                        formdata.append('gender',selectedGender);
                    }
                }

                setLoading(true);
                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };

                await fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false);
                    let rdata = JSON.parse(result);
                    if(rdata.status!==1 && rdata.status!==4){
                        setErrorMessage(rdata.message);
                    }
                    else if(rdata.status===4){
                        window.location.href='/thankyou';
                    }
                    else{
                        //window.location.href = '/team/'+team._id+'/your-forms';
                        if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                            ///Go to thank you page
                            window.location.href='/thankyou';
                        }
                        else{
                            window.location.href=rdata.data.redirct_url;
                        }
                    }
                })
                .catch(error => console.log('error', error));
            }
            else{
                if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                    setLoading(true);
                    let formdata = new FormData();
                    let expiry = cardExpiry.split("/");
                    let today, cardday;
                    today = new Date();
                    cardday = new Date();
                    cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
                    if(cardicon==='amex'){
                        if(cardNum.replace(/\D+/g, '').length<15){
                            setErrorMessage("Invalid card number");
                            if(cardCVV.length<4){
                                setErrorMessage("Invalid card number, cvc number");
                            }
                            return;
                        }
                        if(cardCVV.length<4){
                            setErrorMessage("Invalid CVC number");
                            return;
                        }
                    }
                    else if(cardicon==='dinersclub'){
                        if(cardNum.replace(/\D+/g, '').length<14){
                            setErrorMessage("Invalid card number");
                            return;
                        }
                    }
                    else{
                        if(cardNum.replace(/\D+/g, '').length<16){
                            setErrorMessage("Invalid card number");
                            return;
                        }
                    }
                    if (cardday < today) {
                        setErrorMessage("Invalid expiry date");
                    }
                    else{
                        formdata.append('type','add_card');                    
                        formdata.append('card_number',cardNum);
                        formdata.append('name',cardHolderName);
                        formdata.append('exp_year',expiry[1]);
                        formdata.append('exp_month',expiry[0]);
                        formdata.append('country',country);
                        formdata.append('cvc',cardCVV);
                        formdata.append('is_default_card','Yes');

                        var secureHeader2 = new Headers();
                        secureHeader2.append('Authorization','Bearer ' + token);
                        secureHeader2.append('device_type','W');
                        const requestOptions = {
                            method: 'POST',
                            redirect: 'follow',
                            body: formdata,
                            headers: secureHeader2
                        }
                        
                        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            let rdata = JSON.parse(result);
                            if(rdata.status !== 1){
                                setLoading(false);
                                setErrorMessage(rdata.message);
                            }
                            else{
                                var answers = [];
                                var formdata2 = new FormData();
                                formdata2.append('type','submit_from');
                                formdata2.append('team_id',team._id);
                                formdata2.append('form_id',formItem._id);
                                formdata2.append('card_id',rdata.data[0].id);
                                formdata2.append('sub',data.joinchild_sub);
                                formdata2.append('use_wallet',isWallet);
                                formdata2.append('purchase_in_installment',payInInstallment ? 'Yes' : 'No');
                                if(promoCodeSuccess && promocode!==''){
                                    formdata2.append("promocode",promocode);
                                }
                                data.answers.forEach((dataItem)=>{
                                    let tmpAns = {}; 
                                    if(dataItem.answer!==undefined){
                                        switch(dataItem.question_type){
                                            case 'checkbox':
                                                if(dataItem.answer!==false){
                                                    tmpAns.question_id=dataItem.question;
                                                    tmpAns.answer = dataItem.answer.join('@#');
                                                }
                                                break;
                                            default:
                                                tmpAns.question_id=dataItem.question;
                                                tmpAns.answer = dataItem.answer;
                                        }
                                        if(Object.keys(tmpAns).length>0){
                                            answers.push(tmpAns);
                                        }
                                    }
                                });
                                formdata2.append('answers',JSON.stringify(answers));
                                formdata2.append('separate_by','@#');

                                if(formItem?.product_details!==null && Object.keys(formItem?.product_details).length>0){
                                    if(formItem?.product_details?.is_personalize){
                                        if(shirtNumber!==''){
                                            formdata2.append('shirt_no', shirtNumber);
                                        }
                                        if(shirtName!==''){
                                            formdata2.append('shirt_name', shirtName);
                                        }
                                    }
                                    if(formItem?.product_details?.size!==undefined && formItem?.product_details?.size.length > 0 && selectedSize!==''){
                                        formdata2.append('size',selectedSize);
                                    }
                                    if(formItem?.product_details?.sleeve!==undefined && formItem?.product_details?.sleeve.length > 0 && selectedSleeve!==''){
                                        formdata2.append('sleeve',selectedSleeve);
                                    }
                                    if(formItem?.product_details?.color!==undefined && formItem?.product_details?.color.length > 0 && selectedColor!==''){
                                        formdata2.append('color',selectedColor);
                                    }
                                    if(formItem?.product_details?.gender!==undefined && formItem?.product_details?.gender.length > 0 && selectedGender!==''){
                                        formdata2.append('gender',selectedGender);
                                    }
                                }

                                setLoading(true);
                                var secureHeader = new Headers();
                                secureHeader.append('Authorization','Bearer ' + token);
                                secureHeader.append('device_type','W');
                                const requestOptions = {
                                    method: 'POST',
                                    redirect: 'follow',
                                    body: formdata2,
                                    headers: secureHeader
                                };

                                fetch(process.env.REACT_APP_APIURL + '/app_forms' , requestOptions)
                                .then(response => response.text())
                                .then(result => {
                                    setLoading(false);
                                    let rdata = JSON.parse(result);
                                    if(rdata.status!==1 && rdata.status!==4){
                                        setErrorMessage(rdata.message);
                                    }
                                    else if(rdata.status===4){
                                        window.location.href='/thankyou';
                                    }
                                    else{
                                        //window.location.href = '/team/'+team._id+'/your-forms';
                                        if(rdata.data.redirct_url===undefined || rdata.data.redirct_url===''){
                                            ///Go to thank you page
                                            window.location.href='/thankyou';
                                        }
                                        else{
                                            window.location.href=rdata.data.redirct_url;
                                        }
                                    }
                                })
                                .catch(error => console.log('error', error));
                            }
                        })
                        .catch(error => console.log('error', error));
                    }            
                }
                else{
                    setErrorMessage("Please fill all fields above.");
                }
            }
        }
    }
    
    const onSubmit=(data)=>{
        if(formItem.product_id===""){
            noPaymentForm(data);
        }
        else{
            withPaymentForm(data);
        }
    }

    const applyPromoCode=async()=>{
        setPromoCodeerror(null);
        if(promocode!==""){
            setPromoLoading(true);            
            setPromoCodeSuccess(false);
            let formdata = new FormData();
            formdata.append('type', 'apply_promocode');
            formdata.append('team_id', team._id);
            formdata.append('promocode',promocode);
            if(payInInstallment){
                formdata.append('amount',formItem.product_details?.installments[0]?.price);
            }
            else{
                formdata.append('amount',formItem.product_details.display_price);
            }
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_promocode', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setPromoLoading(false);
                    var rs = JSON.parse(result);
                    if (rs.status!==1) {
                        setPromoCodeerror(rs?.message);
                    }
                    else {
                        setPromoCodeSuccess(true);
                        setPromoDiscountAmount(rs?.discount_amount);
                    }
                })
                .catch(error => console.log('error', error));
        }
        else{
            setPromoCodeerror("Please Enter Promocode");
        }
    }

    const updateDiscountAction=async(isInstallment)=>{
        setPromoCodeerror(null);
        if(promocode!==""){
            setPromoLoading(true);            
            setPromoCodeSuccess(false);
            let formdata = new FormData();
            formdata.append('type', 'apply_promocode');
            formdata.append('team_id', team._id);
            formdata.append('promocode',promocode);
            if(isInstallment){
                formdata.append('amount',formItem.product_details?.installments[0]?.price);             
            }
            else{
                formdata.append('amount',formItem.product_details.display_price);
            }
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_promocode', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setPromoLoading(false);
                    var rs = JSON.parse(result);
                    if (rs.status!==1) {
                        setPromoCodeerror(rs?.message);
                    }
                    else {
                        setPromoCodeSuccess(true);
                        setPromoDiscountAmount(rs?.discount_amount);
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    const fetchPaymentCard = async()=> {
        setLoading(true);
        const [mycardsData, joinedChildData, myWallet] = await Promise.all([
            getMyPaymentCards(token),
            getJoinedChild(formItem._id,team._id,token),
            getWallet(token, team._id)
        ]);
        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
            mycardsData.data.forEach(element => {
                if(element.default_source){
                    setCurrentCardID(element.id);
                }
            });
        }
        
        //var joinedChildArr=[{'name': userDetail.FirstName+' '+userDetail.LastName, 'sub':userDetail.sub}];
        var joinedChildArr=[];

        if(joinedChildData.data.length>0){
            joinedChildData.data.forEach(element=>{
                joinedChildArr.push({'name': element.name, 'sub':element.sub})
            })
        }
        setJoinedChild(joinedChildArr);

        if(myWallet.data.length >0 && myWallet.data[0].amount!=="" && myWallet.data[0].amount!=="0.00" && myWallet.data[0].amount!==0){
            setWalletData(myWallet.data);
            setWallet(true);
            setWalletTransactionFee(myWallet.wallet_transaction_fee/100);
        }
        setLoading(false);
    };

    const handleChangeSize=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, sizeError:false});
        }
        setSelectedSize(e.target.value);
    }
    const handleChangeColor=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, colorError:false});
        }
        setSelectedColor(e.target.value);
    }
    const handleChangeSleeve=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, sleeveError:false});
        }
        setSelectedSleeve(e.target.value);
    }
    const handleChangeGender=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, genderError:false});
        }
        setSelectedGender(e.target.value);
    }
    const handleChnageShirtNumber=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, shirtNumberError:false});
        }
        setShirtNumber(e.target.value);
    }
    const handleChnageShirtName=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, shirtNameError:false});
        }
        setShirtName(e.target.value);
    }

    useEffect(() => {
        fetchPaymentCard();
    }, []);

    useEffect(()=>{
        if(formItem.product_details!==null && Object.keys(formItem?.product_details).length>0){
            if(formItem?.product_details?.size.length>0){
                setSelectedSize(formItem?.product_details?.size[0]);
            }
            if(formItem?.product_details?.sleeve.length>0){
                setSelectedSleeve(formItem?.product_details?.sleeve[0]);
            }
            if(formItem?.product_details?.color.length>0){
                setSelectedColor(formItem?.product_details?.color[0]);
            }
            if(formItem?.product_details?.gender.length>0){
                setSelectedGender(formItem?.product_details?.gender[0]);
            }
        }
    },[formItem]);

    useEffect(()=>{
        if(isWallet){
            if(payInInstallment){
                if(parseFloat(walletData[0].amount) < ((parseFloat(formItem?.product_details?.installments[0].price) + walletTransactionFee) /100).toFixed(2)){
                    setIsCardPayment(true);
                }
                else{
                    setIsCardPayment(false);
                }
            }
            else{
                if(parseFloat(walletData[0].amount) < ((formItem?.product_details?.price + walletTransactionFee) /100).toFixed(2)){
                    setIsCardPayment(true);
                }
                else{
                    setIsCardPayment(false);
                }
            }
        }
        else{
            setIsCardPayment(true);
        }
    },[isWallet, payInInstallment]);
    
    return(
        <>
        {!loading ?
        <>
            <div className="form-detail-card bg-white py-6 px-5 lg:px-9.5 relative">
                <div className='flex flex-col md:flex-row md:gap-5 justify-between items-start'>
                    <div>
                        <h2 className="form-card-title font-bold text-lg/6 mb-4.5">{formItem.title}</h2>
                        <div className="tracking-[0.01em] font-light text-xs mb-5 md:mb-7">{formItem.description}</div>
                    </div>
                    <div className='mb-7 md:mb-0'>
                        {formItem.submited_at!=="" ?
                        <>
                        <div className="completed-status">
                            <div className="flex-1">
                                <div className="tracking-[0.01em] text-xs">Completed on</div>
                                <div className="tracking-[0.01em] text-sm font-semibold">{dayjs.utc(formItem.submited_at).local().format("DD MMM YYYY | hh:mm A")}</div>
                            </div>
                        </div>
                        </>
                        :
                        <>
                        <div className="notstarted-status text-xs font-medium">Incomplete</div>
                        </>
                        }
                    </div>
                </div>
                {formItem.product_id!=="" &&
                    <div className='flex gap-1 mb-5'>
                        <img src={shopIcon} width={20} height={20} alt=""/>
                        <span className='text-sm'>{formItem.product_name}</span>
                    </div>
                }
                <div className="flex flex-col sm:flex-row gap-5 lg:gap-12.5">
                    <div className="flex items-center">
                        <img src={tinyCalendarIcon} className="w-4 h-4 mr-1.5" alt=""/>
                        <div className="flex flex-1">
                            <div>
                                <div className="tracking-[0.01em] text-xs">Created on</div>
                                <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.created_at).format("DD MMM YYYY")}</div>
                            </div>
                            {formItem.expire_date!=="" &&
                                <>
                                <svg className="mx-4" width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.5 1L0.499999 35.641" stroke="#363F6C" strokeWidth="0.5"/>
                                </svg>
                                <div>
                                    <div className="tracking-[0.01em] text-xs">Expires</div>
                                    <div className="tracking-[0.01em] text-sm font-semibold">{dayjs(formItem.expire_date).format("DD MMM YYYY")}</div>
                                </div>
                                </>
                            }
                        </div>
                    </div>
                    {formItem.create_user!=="" &&
                    <div className="flex items-center">
                        <img src={tinyUserIcon} className="w-4 h-4 mr-1.5" alt=""/>
                        <div className="flex-1">
                            <div className="tracking-[0.01em] text-xs">Created by</div>
                            <div className="tracking-[0.01em] text-sm font-semibold">{formItem.create_user}</div>
                        </div>
                    </div>
                    }
                </div>
                <hr className='my-8'/>
                <div className='questions'>
                    <form onSubmit={handleSubmit(onSubmit)} className='form-wrapper'>
                        {joinedChild.length>1 ?
                        <>
                            <div className='question-box mb-7.5'>
                                <select {...register(`joinchild_sub`)} className='px-4 py-3.4'>
                                    {joinedChild.map((item)=>{
                                        return(
                                            <option value={item.sub} key={item.sub}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </>
                        :
                        <>
                            <input type="hidden" {...register(`joinchild_sub`)} value={userDetail.sub}
                            />
                        </>
                        }
                    
                    {formItem.questions!==undefined &&
                        <>
                        {formItem.questions.map((question, index)=>{
                            return(
                                <div key={question._id} className='question-box mb-7.5'>
                                    <div className='question-index text-xs text-white inline-block px-4 py-1 mb-4'>Question {index+1}</div>
                                    <div className='px-4 pb-5 font-bold text-base tracking-[0.5px]'>
                                        {question.question}{question.is_required.toLowerCase()==='yes' && <sup>*</sup>}
                                    </div>
                                    <div className='relative px-4 pb-5'>
                                        <input type="hidden" {...register(`answers[${index}].question`)} value={question._id} />
                                        <input type="hidden" {...register(`answers[${index}].question_type`)} value={question.question_type} />
                                        {question.question_type==='paragraph' &&
                                        <>
                                            {question.is_required.toLowerCase()==='yes' ?
                                                <textarea className='w-full text-base !bg-white !px-5 py-2.5 rounded-[8px] outline-none'
                                                    {...register(`answers[${index}].answer`, { required: 'This field is required' })} 
                                                />
                                                :
                                                <textarea className='w-full text-base !bg-white !px-5 py-2.5 rounded-[8px] outline-none'
                                                    {...register(`answers[${index}].answer`)} 
                                                />
                                            }
                                        </>
                                        }
                                        {question.question_type==='short_answer' &&
                                            <>
                                            {question.is_required.toLowerCase()==='yes' ?
                                            <input type='text' className='w-full text-base !bg-white !px-5 py-2.5 rounded-[8px] outline-none'
                                                {...register(`answers[${index}].answer`, { required: 'This field is required' })} 
                                            />
                                            :
                                            <input type='text' className='w-full text-base !bg-white !px-5 py-2.5 rounded-[8px] outline-none'
                                                {...register(`answers[${index}].answer`)} 
                                            />
                                            }
                                            </>
                                        }
                                        {question.question_type==='checkbox' &&
                                            <>
                                            {question.is_required.toLowerCase()==='yes' ?
                                                <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                {question.options.map((checkOption, oindex)=>{
                                                    return(
                                                        <label htmlFor={question._id+'-'+oindex} key={oindex} className='flex gap-2.5 items-center'>
                                                            <input 
                                                                id={question._id+'-'+oindex}
                                                                type="checkbox"
                                                                {...register(`answers[${index}].answer`, { 
                                                                    required: 'Select at least one option'
                                                                })}
                                                                value={checkOption}
                                                                className='appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer' 
                                                            />
                                                            <span className='text-xs/3 font-light'>{checkOption}</span>
                                                        </label>
                                                    )
                                                })}                                                        
                                                </div>
                                                :
                                                <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                {question.options.map((checkOption, oindex)=>{
                                                    return(
                                                        <label htmlFor={question._id+'-'+oindex} key={oindex} className='flex gap-2.5 items-center'>
                                                            <input 
                                                                id={question._id+'-'+oindex}
                                                                type="checkbox"
                                                                {...register(`answers[${index}].answer`)}
                                                                value={checkOption}
                                                                className='appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer' 
                                                            />
                                                            <span className='text-xs/3 font-light'>{checkOption}</span>
                                                        </label>
                                                    )
                                                })}                                                        
                                                </div>
                                            }
                                            </>
                                        }
                                        {question.question_type==='single_selection' &&
                                            <>
                                            {question.is_required.toLowerCase()==='yes' ?
                                                <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                {question.options.map((checkOption, oindex)=>{
                                                    return(
                                                        <label htmlFor={question._id+'-'+oindex} key={oindex} className='flex gap-2.5 items-center'>
                                                            <input 
                                                                id={question._id+'-'+oindex}
                                                                type="radio"
                                                                {...register(`answers[${index}].answer`, { 
                                                                    required: 'Select at least one option'
                                                                })}
                                                                value={checkOption}
                                                                className='h-3 w-3 border border-[#363F6C] bg-white align-top float-left mr-1 cursor-pointer' 
                                                            />
                                                            <span className='text-xs/3 font-light'>{checkOption}</span>
                                                        </label>
                                                    )
                                                })}                                                        
                                                </div>
                                                :
                                                <div className='w-full bg-white px-5 py-2.5 rounded-[8px] flex flex-wrap gap-5 items-start'>
                                                {question.options.map((checkOption, oindex)=>{
                                                    return(
                                                        <label htmlFor={question._id+'-'+oindex} key={oindex} className='flex gap-2.5 items-center'>
                                                            <input 
                                                                id={question._id+'-'+oindex}
                                                                type="radio"
                                                                {...register(`answers[${index}].answer`)}
                                                                value={checkOption}
                                                                className='h-3 w-3 border border-[#363F6C] bg-white align-top float-left mr-1 cursor-pointer' 
                                                            />
                                                            <span className='text-xs/3 font-light'>{checkOption}</span>
                                                        </label>
                                                    )
                                                })}                                                        
                                                </div>
                                            }
                                            </>
                                        }
                                        {errors.answers && errors.answers[index]?.answer && (
                                            <p className="error mt-2.5" role="alert">{errors.answers[index].answer.message}</p>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                        {formItem.submited_at==='' &&
                            <>
                                {formItem.product_id!=="" &&
                                    <div className='max-w-[560px]'>
                                        {(formItem.product_details!==null && Object.keys(formItem?.product_details).length>0) &&
                                        <>
                                            {formItem.product_details.size!==undefined && formItem.product_details.size.length>0 &&
                                            <>
                                            <div className='flex justify-between items-center w-full mb-8 max-w-[560px]'>
                                                <span className="mr-5">Select Size</span>
                                                <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                                <select name="size" className='!bg-white !w-35 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' onChange={(e)=>{handleChangeSize(e)}}>
                                                    {formItem.product_details.size.map((size,index)=>{
                                                        return(
                                                            <option key={index} value={size}>{size}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {errorState.sizeError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Size</p>}
                                            </>
                                            }
                                            {formItem.product_details.sleeve!==undefined && formItem.product_details.sleeve.length>0 &&
                                            <>
                                            <div className='flex justify-between items-center w-full mb-8 max-w-[560px]'>
                                                <span className="mr-5">Select Sleeve</span>
                                                <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                                <select name="sleeve" className='!bg-white !w-35 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' onChange={(e)=>{handleChangeSleeve(e)}}>
                                                    {formItem.product_details.sleeve.map((sleeve,index)=>{
                                                        return(
                                                            <option key={index} value={sleeve}>{sleeve}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {errorState.sleeveError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Sleeve</p>}
                                            </>
                                            }
                                            {formItem.product_details.color!==undefined && formItem.product_details.color.length>0 &&
                                            <>
                                            <div className='flex justify-between items-center w-full mb-8 max-w-[560px]'>
                                                <span className="mr-5">Select Color</span>
                                                <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                                <select name="color" className='!bg-white !w-35 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' onChange={(e)=>{handleChangeColor(e)}}>
                                                    {formItem.product_details.color.map((color,index)=>{
                                                        return(
                                                            <option key={index} value={color}>{color}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {errorState.colorError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Color</p>}
                                            </>
                                            }
                                            {formItem.product_details.gender!==undefined && formItem.product_details.gender.length>0 &&
                                            <>
                                            <div className='flex justify-between items-center w-full mb-8 max-w-[560px]'>
                                                <span className="mr-5">Select Gender</span>
                                                <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                                <select name="gender" className='!bg-white !w-35 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' onChange={(e)=>{handleChangeGender(e)}}>
                                                    {formItem.product_details.gender.map((gender,index)=>{
                                                        return(
                                                            <option key={index} value={gender}>{gender}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {errorState.genderError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Gender</p>}
                                            </>
                                            }
                                            {formItem.product_details.is_personalize!==undefined && formItem.product_details.is_personalize &&
                                            <>
                                                <div className='mb-8 max-w-[560px]'>
                                                    <span className="mb-1.5 block">Shirt Number<em>*</em></span>
                                                    <input type="text" name="shirt_number" maxLength="3" className='!bg-white border border-black !px-2.5 !py-2 !text-sm !rounded-xl' onChange={(e)=>{handleChnageShirtNumber(e)}} />
                                                </div>
                                                {errorState.shirtNumberError && <p className='error text-red-600 mb-2.5 -mt-5 text-xs'>Please enter Shirt Number</p>}
                                                <div className='mb-8 max-w-[560px]'>
                                                    <span className="mb-1.5 block">Shirt Name<em>*</em></span>
                                                    <input type="text" name="shirt_name" className='!bg-white border border-black !px-2.5 !py-2 !text-sm !rounded-xl' onChange={(e)=>{handleChnageShirtName(e)}} />
                                                </div>
                                                {errorState.shirtNameError && <p className='error text-red-600 mb-2.5 -mt-5 text-xs'>Please enter Shirt Name</p>}
                                            </>
                                            }
                                        </>
                                        }
                                        {formItem?.product_details?.allow_installment &&
                                        <>
                                            <hr className='mb-8' />
                                            <div className='flex justify-between items-center w-full mb-8 max-w-[560px]'>
                                                <div className="mr-5  flex"><p className='mr-2'>Pay in Instalments?</p></div>
                                                <div className='flex'>
                                                    <input className={`button button-medium installment-button mr-1 ${payInInstallment ? 'btn-disable' : ''}`} type="button" value="No" onClick={() => {
                                                        setPayInInstallment(false);
                                                        setSingleBuyEmi(null)
                                                        updateDiscountAction(false);
                                                    }} />
                                                    <input className={`button button-medium installment-button ml-1 ${!payInInstallment ? 'btn-disable' : ''}`} type="button" value="Yes" onClick={() => {
                                                        setPayInInstallment(true);
                                                        setSingleBuyEmi(formItem.product_details?.installments[0]?.price);
                                                        updateDiscountAction(true);
                                                    }} />
                                                </div>
                                            </div>
                                            <hr className='mb-8' />
                                            <div className='flex justify-between items-center w-full mb-8 max-w-[560px]'>
                                                <div className="mr-5  flex">
                                                    <p className='mr-2 text-[#363F6C]'>You can pay in Installments</p> <span className='cursor-pointer relative'>
                                                        <img src={InfoIcon} alt="" id="installment-info"/>
                                                    </span>
                                                    <Tooltip anchorSelect="#installment-info">
                                                        <div className="w-[280px]">
                                                            <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI INSTALMENT PLANS</h3>
                                                            <div className="mb-4 text-center text-[14px] leading-[19px]">
                                                                PLAI are passionate about removing barriers between people and sport.Sport is for everybody, cost should not be a barrier to entry.You can now break down your team purchase into more manageable chunks, by choosing the PLAI Payment Plan!
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <button type='button' className='text-[#363F6C]' onClick={() => {
                                                    setShowInstallmentDetails(!showInstallmentDetails);
                                                }}>{showInstallmentDetails ? 'Hide' : 'View'}</button>
                                            </div>
                                            {showInstallmentDetails &&
                                                <div className='mb-8'>
                                                    {formItem?.product_details?.installments?.map((installment, index) => {
                                                            return(
                                                                <div className='flex justify-between w-full max-w-[560px] text-sm my-1.5' key={index}>
                                                                    <p className=''>{installment.title}</p>
                                                                    <div className='flex'>
                                                                        <p className='mr-2'>{index===0 ? 'Today' : (installment.date!=="" ? moment(installment.date).format('Do MMM'): "")}</p>
                                                                        <p>{team.currency_symbol}{Number(installment.price / 100).toFixed(2)}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <span className='italic text-[#363F6C] text-xs underline cursor-pointer'>Instalment Plan above is inclusive of the Instalment Plan Activation Fee.</span>
                                                </div>
                                            }
                                        </>
                                        }
                                        {walletData.length > 0 && walletData[0].amount > 0 &&
                                            <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] mb-5'>
                                                <div className='flex justify-between gap-2.5'>
                                                    <div className='flex flex-1 gap-2.5'>
                                                        <div>
                                                            <label className="relative inline-flex items-center cursor-pointer">
                                                                <input type="checkbox" value="" className="sr-only peer" onChange={()=>{setWallet(!isWallet)}} checked={isWallet ? true : false} />
                                                                <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                                                            </label>
                                                        </div>
                                                        <div className="uppercase">Use PLAI Wallet</div>
                                                    </div>
                                                    <div>
                                                        <span className='text-xl font-bold'>{walletData[0].currency_symbol}{parseFloat(walletData[0].amount).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {isWallet &&
                                            <>
                                            <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                <div className='cart-detail-total-label'>PLAI Wallet</div>
                                                <div className='cart-detail-total-value'>
                                                    <span className='text-green-500'>
                                                    {payInInstallment ?
                                                        <>
                                                        {isCardPayment ? 
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2)}</>
                                                                }
                                                            </> 
                                                            : 
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (((parseFloat((formItem?.product_details?.installments[0].price))- promoDiscountAmount)/100) + parseFloat(walletTransactionFee)).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((parseFloat((formItem?.product_details?.installments[0].price))- promoDiscountAmount)/100) + parseFloat(walletTransactionFee)).toFixed(2)}</>
                                                                }
                                                            </>
                                                        }
                                                        </>
                                                        :
                                                        <>
                                                        {isCardPayment ? 
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (((formItem?.product_details?.display_price + formItem?.product_details?.transactionFee) - promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((formItem?.product_details?.display_price + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2)}</>
                                                                }
                                                            </> 
                                                            : 
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (((parseFloat(formItem?.product_details?.display_price)- promoDiscountAmount)/100).toFixed(2) + parseFloat(walletTransactionFee)) ?
                                                                    <>-{walletData[0].currency_symbol}{walletData[0].amount}</>
                                                                    :
                                                                    <>-{walletData[0].currency_symbol}{(((parseFloat(formItem?.product_details?.display_price)- promoDiscountAmount)/100) + parseFloat(walletTransactionFee)).toFixed(2)}</>
                                                                }
                                                            </>
                                                        }
                                                        </>
                                                    }
                                                    </span>
                                                    {payInInstallment ?
                                                        <>
                                                            {isCardPayment ?
                                                            <>
                                                                {parseFloat(walletData[0].amount) - (((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2) >= 0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100)).toFixed(2)}</strong></p>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {parseFloat(walletData[0].amount) - ((parseFloat(formItem?.product_details?.installments[0].price /100) + walletTransactionFee).toFixed(2)) >= 0 &&
                                                                    <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((parseFloat(formItem?.product_details?.installments[0].price)- promoDiscountAmount) /100) + walletTransactionFee)).toFixed(2)}</strong></p>
                                                                }
                                                            </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {isCardPayment ?
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (((parseFloat(formItem?.product_details?.price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2) >=0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - ((parseFloat(formItem?.product_details?.price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                            {parseFloat(walletData[0].amount) - (((parseFloat(formItem?.product_details?.price)- promoDiscountAmount)/100) + parseFloat(walletTransactionFee)).toFixed(2) >=0 &&
                                                                <p className='text-sm font-light'>Remaining Wallet Balance: <strong className='font-bold'>{team.currency_symbol}{(parseFloat(walletData[0].amount) - (((parseFloat(formItem?.product_details?.price) - promoDiscountAmount)/100) + parseFloat(walletTransactionFee))).toFixed(2)}</strong></p>
                                                            }
                                                            </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            {isCardPayment &&
                                                <>
                                                <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                    <div className='cart-detail-total-label'>Remaining</div>
                                                    <div className='cart-detail-total-value'>
                                                        {payInInstallment ?
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{(((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee)- promoDiscountAmount) /100).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {parseFloat(walletData[0].amount) <= (((parseFloat(formItem?.product_details?.price) + formItem?.product_details?.transactionFee)- promoDiscountAmount)/100).toFixed(2) ?
                                                                    <>{walletData[0].currency_symbol}{((((parseFloat(formItem?.product_details?.price) + formItem?.product_details?.transactionFee)- promoDiscountAmount)/100) - parseFloat(walletData[0].amount)).toFixed(2)}</>
                                                                    :
                                                                    <>{walletData[0].currency_symbol}0.00</>                                                                    
                                                                }
                                                            </> 
                                                        }
                                                    </div>
                                                </div>
                                                </>
                                            }
                                            </>
                                        }
                                        {promoDiscountAmount!==0 &&
                                            <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                                <div className='cart-detail-total-label'>Discount</div>
                                                <div className='cart-detail-total-value'>
                                                    {team.currency_symbol}{(promoDiscountAmount / 100).toFixed(2)}
                                                </div>
                                            </div>
                                        }
                                        <div className='cart-detail-row cart-detail-total flex justify-between pb-5 mb-5'>
                                        {payInInstallment ?
                                            <>
                                                {isCardPayment ?
                                                    <>
                                                        <div className='cart-detail-total-label'>To Pay Today: </div>
                                                        <div className='cart-detail-total-value'>                                      
                                                            <span>{team.currency_symbol}{(((parseFloat(formItem?.product_details?.installments[0].price) + formItem?.product_details?.transactionFee) - promoDiscountAmount) / 100).toFixed(2)}</span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='cart-detail-total-label'>To Pay Today: </div>
                                                        <div className='cart-detail-total-value'>                                      
                                                            <span>{team.currency_symbol}{((((parseFloat(formItem?.product_details?.installments[0].price) - promoDiscountAmount))/100) + parseFloat(walletTransactionFee)).toFixed(2)}</span>
                                                        </div>
                                                    </>
                                                }                                                
                                            </>
                                            :
                                            <>
                                            {isCardPayment ?
                                                <>
                                                    <div className='cart-detail-total-label'>Total: </div>
                                                    <div className='cart-detail-total-value'>
                                                        <span>{team.currency_symbol}{((parseFloat(formItem.product_details.display_price_with_transactionfee) - promoDiscountAmount)/100).toFixed(2)}</span>
                                                    </div>                                                    
                                                </>
                                                :
                                                <>
                                                    <div className='cart-detail-total-label'>Total: </div>
                                                    <div className='cart-detail-total-value'>                                          
                                                        <span>{team.currency_symbol}{(((parseFloat(formItem.product_details.display_price) - promoDiscountAmount)/100) + parseFloat(walletTransactionFee)).toFixed(2)}</span>
                                                    </div>                                                
                                                </>
                                            }      
                                            </>
                                        }
                                        </div>
                                        <div className='flex justify-between items-center gap-4 mt-5'>
                                            <input type='text' defaultValue={''} onChange={(e)=>{setPromocode(e.target.value)}} placeholder='Enter Discount Code' className='!pl-4' ref={promoRef} readOnly={promoCodeSuccess ? true : false} />
                                            {promoCodeSuccess ?
                                            <>                                                            
                                                <button type='button' className='button button-blue' onClick={()=>{setPromoCodeSuccess(false); setPromoDiscountAmount(0); setPromocode(''); promoRef.current.value=''}}>Remove</button>
                                            </>                                                            
                                            :
                                            <>
                                                {!promoloading ?
                                                    <button type='button' className='button button-blue' onClick={applyPromoCode}>Apply</button>
                                                    :
                                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                }
                                            </>                                                            
                                            }
                                        </div>
                                        {promoCodeError!==null && <p className="error text-red-600 mb-2.5">{promoCodeError}</p>}
                                        {(!isWallet || (isWallet && isCardPayment)) &&
                                        <>
                                        {paymentCards.length > 0 ? 
                                            <>  
                                                <label>Select card</label>
                                                <div className='form-field-wrap w-full  mb-5 no-field-wrap relative'>
                                                    <select onChange={(e)=>{setCurrentCardID(e.target.value)}}>
                                                        {paymentCards.map((card) => {
                                                            return(
                                                                <option key={card.id} value={card.id}>****{card.last4} ({card.name})</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </>
                                        :
                                            <div className='flex flex-wrap max-w-md'>
                                                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                    <input 
                                                        placeholder="0000 0000 0000 0000" className='text-field' 
                                                        type='tel'
                                                        name='number'
                                                        maxLength='19'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            e.target.value = formatCreditCardNumber(e.target.value);
                                                            setCardNum(e.target.value);
                                                            let icon = getIsUser(e.target.value);
                                                            setCardIcon(icon);
                                                            setErrorMessage(null);
                                                        }}
                                                    />
                                                    <div className='absolute top-[18px] right-[20px]'>
                                                        <CardIcon value={cardicon}/>
                                                    </div>
                                                </div>
                                                <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                                                    <input 
                                                        placeholder="MM/YY" className='text-field' 
                                                        type='tel'
                                                        name='expiry'
                                                        pattern='\d\d/\d\d'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            e.target.value = formatExpirationDate(e.target.value);
                                                            setCardExpiry(e.target.value);
                                                            setErrorMessage(null);
                                                        }}
                                                    />                                
                                                </div>
                                                <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pl-1 relative'>
                                                    <input 
                                                        type='tel'
                                                        name='cvc'
                                                        className='text-field'
                                                        placeholder='CVC'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            e.target.value = formatCVC(e.target.value, {number:cardNum});
                                                            setCardCVV(e.target.value);
                                                            setErrorMessage(null);
                                                        }}
                                                    />                                
                                                </div>
                                                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                                    <input 
                                                        type='text'
                                                        name='name'
                                                        className='text-field'
                                                        placeholder='Card Holder Name'
                                                        required
                                                        autoComplete="off"
                                                        onChange={(e)=>{
                                                            setCardName(e.target.value);
                                                            setErrorMessage(null);
                                                        }}
                                                    />                                
                                                </div>
                                                <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                                                    <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                                        <option value="">Select Country *</option>
                                                        {CountriesList.map((c,cindex)=>{
                                                            return(
                                                                <option key={cindex} value={c.code}>{c.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        </>
                                        }
                                    </div>
                                }
                                <div className='flex items-center justify-center'>
                                    <button className='button lg:button--large' type="submit">Submit Form</button>
                                </div>
                            </>
                        }
                        </>
                    }
                    </form>
                    {errorMessage!==null &&
                        <p className='text-red-700 error my-5 px-4 text-center'>{errorMessage}</p>
                    }
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
        }
        </>
    )
}
const YourFormResponse=()=>{
    const { id, formid } = useParams();
    
    return (
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Forms" />
                    <ResponseFormView formId={formid} />
                </div>
            </TeamContextProvider>
        </>
    );
}
export default YourFormResponse;