import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useParams, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import { getRemainingMatch, getGroupsV2} from "../../../libs/dataFunctions";
import FixtureMigrationRow from "./FixtureMigrationRow";
import NoData from '../../../assets/images/noevents.png';
import Spinner from '../../../components/Spinner/Spinner';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(customParseFormat);

const FixtureMigrationView=()=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [remainingmatches, setRemainingMatches] = useState([]);
    const [loading,setLoading] = useState(false);
    const [parentGroups,setParentGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [checkedDataList, setCheckedDataList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchRemainingMatches=async()=>{
        setLoading(true);
        const [groupsData, remainingData] = await Promise.all([
            getGroupsV2(team._id, token),
            getRemainingMatch(token, team._id)
        ]);
        setRemainingMatches(remainingData.data);
        let groupOptions = [];
        let parentGroupOptions = [];
        
        groupsData.forEach(element => {
            let childOptions = [];
            parentGroupOptions.push({'value':element._id, 'label':element.name});
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});                
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }
        });
                
        setParentGroups(parentGroupOptions);
        setGroups(groupOptions);
        setLoading(false);
    }

    const onsubmit=async()=>{
        setErrorMessage(null);
        setSuccessMessage(null);
        var hasErrorMain = false;
        if(checkedDataList.length>0){
            var updateList = [];
            updateList = checkedDataList.map((item)=>{
                let newItem = {...item};
                let hasError = false;
                newItem.isError = false;
                newItem.isStartTimeError = false;
                newItem.isEndTimeError = false;
                newItem.isGroupError = false;
                newItem.isCoachErorr = false;
                newItem.isEndTimeSmallError = false;
                
                if(item.timeStart===""){
                    hasError=true;
                    newItem.isError = true;
                    newItem.isStartTimeError = true;
                }
                if(item.timeEnd===""){
                    hasError=true;
                    newItem.isError = true;
                    newItem.isEndTimeError = true;
                }
                else{
                    var dtStart = new Date("1/1/2011 " + item.timeStart);
                    var dtEnd = new Date("1/1/2011 " + item.timeEnd);
                    var difference_in_milliseconds = dtEnd - dtStart;
                    if (difference_in_milliseconds < 0)
                    {
                        hasError=true;
                        newItem.isError = true;
                        newItem.isEndTimeSmallError = true;
                    }
                }
                if(item.groups===""){
                    hasError=true;
                    newItem.isError = true;
                    newItem.isGroupError = true;
                }
                /*if(item.coaches_new===""){
                    hasError=true;
                    newItem.isError = true;
                    newItem.isCoachErorr = true;
                }*/
                if(!hasError){
                    newItem.isCompleted = true;
                }
                else{
                    hasErrorMain = true;
                }                
                return newItem;
            });            
            setCheckedDataList(updateList);            
            if(hasErrorMain){
                setErrorMessage("There are some information missing, please check above selected Fixtures and Enter required information");
            }
            else{
                var submittedJson = [];
                updateList.forEach((item)=>{
                    let tmpItem = {};
                    tmpItem.event_match_id = item.event_match_id;
                    tmpItem.name = item.name;
                    tmpItem.sub_title = item.sub_title;
                    tmpItem.description = item.description;
                    tmpItem.dateStart = dayjs.utc(item.dateStart, 'DD/MM/YYYY').format("YYYY-MM-DD");
                    tmpItem.timeStart = item.timeStart;
                    tmpItem.timeEnd = item.timeEnd;
                    tmpItem.timezone = team.timezone;
                    tmpItem.location = item.location;
                    tmpItem.latitude = item.latitude!==undefined ? item.latitude : '';
                    tmpItem.longitude = item.longitude!==undefined ? item.longitude : '';
                    tmpItem.group_ids = item.groups;
                    tmpItem.coaches_new = item.coaches_new;

                    submittedJson.push(tmpItem);
                });

                if(submittedJson.length > 0){
                    var formdata = new FormData();
                    formdata.append("type", "create_event_from_scorecard");
                    formdata.append("team_id", team._id);
                    formdata.append('eventsArr',JSON.stringify(submittedJson));
                    formdata.append('separate_by','@#');
                    var secureHeader = new Headers();
                    secureHeader.append('Authorization','Bearer ' + token);
                    secureHeader.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader
                    };
                    setLoading(true);                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        setLoading(false);
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setSuccessMessage(null);
                            setErrorMessage(rdata.message);
                        }
                        else{
                            setSuccessMessage(rdata.message);
                            setErrorMessage(null);
                            window.location.href = '/team/'+team._id+'/events';                            
                        }
                    })
                    .catch(error => console.log('error', error));
                }
            }
        }
        else{
            setErrorMessage("Please Select Fixture");
        }
    }

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchRemainingMatches();
        }
    },[teamUpdating]);

    return(
        <>
        {!loading ?
            <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/events`}>
                    Events
                </NavLink>
                <span className='separator'>&lt;</span>
                Manage Fixtures
            </div>
            <div className='body px-5 lg:px-8 py-10'>
                <div className='rounded-xl bg-white pb-10'>
                    <div className="tab-actionbar px-2.5 sm:px-7.5 py-4.5 mb-7.5">
                        <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Play-Cricket Fixture Migration</div>                                       
                    </div>
                    <div className='px-5 lg:px-8'>
                        {remainingmatches.length > 0 ?
                            <>
                                <div>
                                    <table className="table-auto w-full mb-10">
                                        <thead>
                                            <tr className='border-b border-black-100/10'>
                                                <th className="text-left py-[18px]">Fixture</th>
                                                <th className="text-left py-[18px]">Date</th>
                                                <th className="text-left py-[18px]">Start time</th>                                    
                                                <th className="text-left py-[18px]">End time</th>
                                                <th className="text-left py-[18px] w-[300px]">Location</th>
                                                <th className="text-left py-[18px]">Assign duty</th>
                                                <th className="text-left py-[18px]">Assign group</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {remainingmatches.map((ritem)=>{
                                            return(
                                                <FixtureMigrationRow
                                                    key={ritem.id}
                                                    fixtureItem={ritem}
                                                    team={team}
                                                    token={token}
                                                    parentGroups={parentGroups}
                                                    groups={groups}
                                                    setCheckedDataList={setCheckedDataList}
                                                    checkedDataList={checkedDataList}
                                                />
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                    <div className='flex items-center justify-center'>
                                        <button className='button button--large flex gap-1.5 items-center' onClick={()=>{onsubmit()}}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 2.69971V15.2997" stroke="currentColor" strokeWidth="2"/>
                                                <path d="M15.3003 8.99951L2.70029 8.99951" stroke="currentColor" strokeWidth="2"/>
                                            </svg>
                                            <span>Confirm</span>
                                        </button>
                                    </div>
                                    {errorMessage!==null &&
                                        <div className="w-full mt-5">
                                            <div className='flex justify-center mb-10'>
                                                <p className='error text-center mb-2.5 text-red-600'>{errorMessage}</p>
                                            </div>
                                        </div>
                                    }
                                    {successMessage!==null &&
                                        <div className="w-full mt-5">
                                            <div className='flex justify-center mb-10'>
                                                <p className='text-center mb-2.5 text-green-600'>{successMessage}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        :
                            <>
                                <div className="empty text-center">
                                    <div className="image"><img src={NoData} alt="Plaisport" width={228} /></div>                                        
                                    <div className="title mb-5">No remaining fixture found</div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            </>
        :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
        }
        </>
    )
}

const FixtureMigration=()=>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Fixture Migrate" />
                    <FixtureMigrationView />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default FixtureMigration;