import { useEffect, useState } from "react";
import dayjs from "dayjs";
import IsMobile from "../../../components/isMobile";
import IsTablet from "../../../components/isTablet";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const OrderRow = ({orders, team, refreshShopOrders, ordersType}) =>{
    const [loadingCancel, setLoadingCancel] = useState(false);
    const [loadingSendPayment, setLoadingSendPayment] = useState(false);
    const isMobile = IsMobile(window);
    const isTablet = IsTablet(window);
    const [showInstallMentSchedule, setShowInstallmentSchedule] = useState(false);
    const [loadingDstatus, setLoadingDstatus] = useState(false);
    const [orderDeliveryStatus, setOrderDeliveryStatus] = useState('');
    const [loadingRefundstatus, setLoadingRefundstatus] = useState(false);
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const copyToClipboard=(data) => {
      let textField = document.createElement('textarea');
      textField.innerText = data;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      alert('Link copied!');
    }
    let statusBtnColorCls;
    let textColor;
    if(orders.status === 'Succeeded' || orders.status === 'Cash'){
      statusBtnColorCls = 'sucess';
      textColor = 'text-[#257d3e]';
    } 
    else if(orders.status === 'Requested'){
      statusBtnColorCls = 'info';
      textColor = 'text-[#4a60a5]';
    }
    else if(orders.status === 'Incomplete'){
      statusBtnColorCls = 'red-dark';
      textColor = 'text-[#f01515]';
    }
    else if(orders.status === 'Failed'){
      statusBtnColorCls = 'red-dark';
      textColor = 'text-[#f01515]';
    }
    else if(orders.status === 'Cancelled'){
      statusBtnColorCls = 'red-dark';
      textColor = 'text-[#f01515]';
    }
    else if(orders.status.toLowerCase() === 'overdue'){
      statusBtnColorCls = 'red-dark';
      textColor = 'text-[#f01515]';
    }
    else{
      statusBtnColorCls = 'orange';
      textColor = 'text-[#f2a93b]';
    }

    if(orders.order_type!==undefined && orders.order_type === 'tips'){
      statusBtnColorCls = 'sucess';
      textColor = 'text-[#257d3e]';
    }

    const changeDeliveryStatus=async(dstatus)=>{
      setLoadingDstatus(true);
      let formdata = new FormData();
      formdata.append('type', 'update_order_status');
      formdata.append('team_id', team._id);
      formdata.append('order_id', orders._id);
      formdata.append('is_delivered', dstatus);      
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
      .then(response => response.text())
      .then(result => {
        setLoadingDstatus(false);
        let rdata = JSON.parse(result);
        if(rdata.status === 1){
          setOrderDeliveryStatus(dstatus);
          //refreshShopOrders();
        }
      })
      .catch(error => console.log('error', error));
    }

    const RefundOrder=async()=>{
      setLoadingRefundstatus(true);
      let formdata = new FormData();
      formdata.append('type', 'refund_payment');
      formdata.append('team_id', team._id);
      formdata.append('order_id', orders._id);    
      var secureHeader = new Headers();
      secureHeader.append('Authorization','Bearer ' + token);
      secureHeader.append('device_type','W');
      const requestOptions = {
          method: 'POST',
          redirect: 'follow',
          body: formdata,
          headers: secureHeader
      };
      await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
      .then(response => response.text())
      .then(result => {
        setLoadingRefundstatus(false);
        let rdata = JSON.parse(result);
        if(rdata.status === 1){
          refreshShopOrders(ordersType);
        }
        else{
          alert(rdata.message);
        }
      })
      .catch(error => console.log('error', error));
    }

    const cancelRequest = async() =>{   
      if(window.confirm("Are you sure you want to cancel this request?")){        
          setLoadingCancel(true);
          let formdata = new FormData();
          formdata.append('type', 'cancel_payment_link');
          formdata.append('team_id', team._id);
          formdata.append('order_id', orders._id);
          var secureHeader = new Headers();
          secureHeader.append('Authorization','Bearer ' + token);
          secureHeader.append('device_type','W');
          const requestOptions = {
              method: 'POST',
              redirect: 'follow',
              body: formdata,
              headers: secureHeader
          };
          await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
          .then(response => response.text())
          .then(result => {
              setLoadingCancel(false);
              let rdata = JSON.parse(result);
              if(rdata.status === 1){
                refreshShopOrders(ordersType);
              }
              else{
                alert(rdata.message);
              }
          })
          .catch(error => console.log('error', error));
      }
    }

    const sendPaymentRequest = async(orderID, setLoadingStat) =>{
      setLoadingStat(true);
        let formdata = new FormData();
        formdata.append('type', 'send_payment_link');
        formdata.append('team_id', team._id);
        formdata.append('order_id', orderID);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoadingStat(false);
            let rdata = JSON.parse(result);
            if(rdata.status === 1){
              alert(rdata.message);
            }
        })
        .catch(error => console.log('error', error));
    }

    const InstallmentSchedule = ({installments,setShow}) =>{
      return(
        <>
          <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[650px] !px-7.5 relative">
                    <button type="button" className="close-popup" onClick={(e)=>{setShow(false)}}><span>close</span></button>
                    <div className="border-b border-[#fbdb09] pb-2.5 mb-5">
                      <h2 className="uppercase font-semibold">Installment Schedule</h2>
                      <p className="text-[10px]">inclusive of all fees and taxes</p>
                    </div>
                    {installments.map((installment)=>{
                      return(
                        <InstallmentScheduleRow installment={installment} />
                      )
                    })}
                </div>
            </div>
        </>
      )
    }

    const InstallmentScheduleRow = ({installment}) =>{
      const [iloading,setIloading] = useState(false);
      let istatusBtnColorCls;
      if(installment.status === 'Succeeded'){
        istatusBtnColorCls = 'sucess';
      } 
      else if(installment.status === 'Requested'){
        istatusBtnColorCls = 'info';
      }
      else if(installment.status === 'Incomplete'){
        istatusBtnColorCls = 'red-dark';
      }
      else if(installment.status === 'Failed'){
        istatusBtnColorCls = 'red-dark';
      }
      else if(installment.status === 'Canceled'){
        istatusBtnColorCls = 'red-dark';
      }
      else if(installment.status.toLowerCase() === 'overdue'){
        istatusBtnColorCls = 'red-dark';
      }
      else{
        istatusBtnColorCls = 'orange';
      }
      return(
        <div className="flex justify-between mb-2.5 pb-2.5 border-b border-slate-300" key={installment._id}>
          <div className="mr-2.5 flex-1">
            <div className="text-[16px] leading-[22px] text-[#363F6C]">{installment.title}</div>
            <div className="font-semibold italic text-[14px] leading-[18px] text-[#363F6C]">{dayjs(installment.date).format("DD MMM YYYY")}</div>
          </div>
          <div className="mr-2.5 justify-self-end font-bold">{installment.amount}</div>
          <div className="justify-self-end">
            <span className={`rounded-full px-4 py-1 mb-2.5 block max-w-[120px] text-center ${istatusBtnColorCls}`}>{installment.status}</span>
            {(installment.status.toLowerCase() === 'overdue') &&
              <>
              {!iloading ?
                <button className="cancel-link rounded-full px-2 py-1 mb-2.5" onClick={()=>{sendPaymentRequest(installment._id, setIloading);}}>Send Request</button>
              :
                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
              }
              </>
            }
          </div>
        </div>
      )
    }

    const personalizeInformation=()=>{
      confirmAlert({
          customUI: ({ onClose }) => {
              return (
              <div className='confirm-ui w-96 !max-w-[480px]'>
                  <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">Order Details</div>
                      {orders.shirt_no!=='' &&
                            <div className='flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                <span className="mb-1.5 block">Shirt Number</span>
                                <div className="font-medium">{orders.shirt_no}</div>
                            </div>
                      }
                      {orders.shirt_name!=='' &&
                          <div className='flex justify-between items-center w-full mb-8 max-w-[448px]'>
                              <span className="mb-1.5 block">Shirt Name</span>
                              <div className="font-medium">{orders.shirt_name}</div>
                          </div>
                      }
                      {orders.size!=='' &&
                      <div className='flex justify-between items-center w-full mb-8 max-w-[448px]'>
                          <span className="mr-5">Size</span>
                          <div className="font-medium">{orders.size}</div>
                      </div>
                      }
                      {orders.sleeve!=='' &&
                      <div className='flex justify-between items-center w-full mb-8 max-w-[448px]'>
                          <span className="mr-5">Sleeve</span>
                          <div className="font-medium">{orders.sleeve}</div>
                      </div>
                      }
                      {orders.color!=='' &&
                      <div className='flex justify-between items-center w-full mb-8 max-w-[448px]'>
                          <span className="mr-5">Color</span>
                          <div className="font-medium">{orders.color}</div>
                      </div>
                      }
                      {orders.gender!=='' &&
                      <div className='flex justify-between items-center w-full mb-8 max-w-[448px]'>
                          <span className="mr-5">Select Gender</span>
                          <div className="font-medium">{orders.gender}</div>
                      </div>
                      }
                  <div className="flex justify-center">
                      <button className="button button-blue mx-1" onClick={onClose}>Close</button>                        
                  </div>
              </div>
              );
          }
      });
  }

    useEffect(()=>{
      if(orders.is_delivered!==undefined){
        setOrderDeliveryStatus(orders.is_delivered);
      }
      else{
        setOrderDeliveryStatus('');
      }
    },[orders]);

    return(
      <>
        {!isMobile && !isTablet?
          <>
            <div className="table-row">
              <div className='table-cell py-5 pl-7 pr-1.5 text-[#363F6C] font-medium'>
              {((orders.is_personalize!==undefined && orders.is_personalize) || (orders.color!==undefined && orders.color!=="") || (orders.gender!==undefined && orders.gender!=="") || (orders.sleeve!==undefined && orders.sleeve!=="") || (orders.size!==undefined && orders.size!=="")) ?
                <div className="flex gap-2.5">
                    <div>{orders.product_name}</div>
                    <button onClick={()=>{personalizeInformation()}} className="w-[20px] lg:w-[30px]">
                        <svg width="15" height="15" className="w-full" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#363F6C"/>
                            <path d="M16.9398 12.6015L13.1752 13.0733L13.0404 13.698L13.7802 13.8344C14.2635 13.9495 14.3588 14.1238 14.2536 14.6054L13.0404 20.3066C12.7215 21.7812 13.213 22.4749 14.3687 22.4749C15.2646 22.4749 16.3052 22.0607 16.7771 21.4919L16.9217 20.808C16.5929 21.0973 16.1129 21.2124 15.794 21.2124C15.3419 21.2124 15.1775 20.8951 15.2942 20.3362L16.9398 12.6015ZM17.0549 9.16895C17.0549 9.60495 16.8817 10.0231 16.5734 10.3314C16.2651 10.6397 15.8469 10.8129 15.4109 10.8129C14.9749 10.8129 14.5568 10.6397 14.2485 10.3314C13.9402 10.0231 13.767 9.60495 13.767 9.16895C13.767 8.73296 13.9402 8.31482 14.2485 8.00652C14.5568 7.69822 14.9749 7.52502 15.4109 7.52502C15.8469 7.52502 16.2651 7.69822 16.5734 8.00652C16.8817 8.31482 17.0549 8.73296 17.0549 9.16895Z" fill="#FBDB03"/>
                        </svg>                
                    </button>
                </div>
                :      
                <>{orders.product_name}</>
                }
                {orders.installment_number!=="" &&
                  <div className={`text-[12px] font-medium ${textColor}`}>{orders.installment_number}</div>
                }
                {orders.installments!==undefined && orders.installments.length > 0 &&
                  <button className="mt-4 underline hover:no-underline text-[12px]" onClick={()=>{setShowInstallmentSchedule(!showInstallMentSchedule)}}>View installments &gt;&gt;</button>
                }
              </div>
              <div className='table-cell py-5 pr-1.5'>
                <div>{orders.name}</div>
                {(orders.notes!=="" && orders.notes!==undefined) &&
                  <div className="text-[11px]">{orders.notes}</div>
                }
              </div>
              <div className='table-cell py-5 pr-1.5 min-w-[140px]'>
                {ordersType==='get_orders_v2' ?
                <>
                  {dayjs(orders.date).format("MMM DD YYYY hh:mm A")}
                </>
                :
                <>
                  {dayjs(orders.date).format("MMM DD YYYY")}
                </>
                }
                
              </div>
              <div className='table-cell py-5 pr-1.5 w-[120px] !font-medium'>{orders.currency_symbol}{orders.amount}</div>
              <div className='table-cell py-5 pr-1.5'>
                {orders.status.toLowerCase() === 'requested' ? 
                  <div className="text-[14px] lading-[21px] cursor-pointer text-[#363F6C]" onClick={()=>copyToClipboard(orders.stripe_id)}>Copy link</div>
                : 
                <>
                {orders.status.toLowerCase() === 'succeeded' ? 
                  <>
                    {orders.stripe_id!==undefined && orders.stripe_id!=="" && orders.stripe_url!=="" ? 
                      <>
                      <a href={orders.stripe_url} target="_blank" rel="noreferrer">
                        {orders.stripe_id.substring(0, 30)+'...'}
                      </a>
                      </>
                      : 
                      null
                    }
                  </>
                :
                  <>
                  {orders.status === 'Cash' ? orders.collected_by: (orders.stripe_id!==undefined && orders.stripe_id!=="" ? orders.stripe_id.substring(0, 30)+'...' : null)}
                  </>
                }                
                </>
                }
              </div>
              <div className='table-cell text-left pr-7 w-[120px] order-status'>
                <span className={`rounded-full px-4 py-1 mb-2.5 block max-w-[120px] text-center ${statusBtnColorCls}`}>{orders.status_title}</span>
                {(orders.status.toLowerCase() === 'requested' && orders._id !==undefined && orders._id !=='') &&
                  <>
                  {!loadingCancel ?
                    <button className="cancel-link rounded-full px-4 py-1 mb-2.5 max-w-[120px]" onClick={()=>{cancelRequest();}}>Cancel Request</button>
                  :
                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                  }                
                  </>
                }
                {(orders.status.toLowerCase() === 'succeeded' && orders._id !==undefined && orders._id !=='') &&
                  <>
                  {!loadingRefundstatus ?
                    <button className="cancel-link rounded-full px-4 py-1 mb-2.5 max-w-[120px]" onClick={()=>{RefundOrder();}}>Refund</button>
                  :
                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                  }                
                  </>
                }
                {(orders.status.toLowerCase() === 'overdue') &&
                  <>
                  {!loadingSendPayment ?
                    <button className="cancel-link rounded-full px-2 py-1 mb-2.5 w-full max-w-[120px]" onClick={()=>{sendPaymentRequest(orders._id, setLoadingSendPayment);}}>Send Request</button>
                  :
                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                  }
                  </>
                }
                {(orders.is_required_delivery.toLowerCase() === 'yes') &&
                  <>
                  {!loadingDstatus ?
                    <>
                    {orders.status.toLowerCase() === 'succeeded' &&
                    <>
                      {(orderDeliveryStatus.toLowerCase() === 'yes') ?
                      <>
                        <div className="relative">                                
                          <label htmlFor={`delivery_status-${orders._id}`} className='pl-5 text-[0.725rem] text-[#363F6C] font-medium'>
                            <input type="checkbox" id={`delivery_status-${orders._id}`} className='absolute left-[0] top-[3px] w-[16px] h-[16px] border border-[#363F6C] rounded-[5px] appearance-none' onClick={(e)=>{changeDeliveryStatus('No');}} defaultChecked/>
                            Delivered
                          </label>
                        </div>
                      </>
                      :
                      <>
                        <div className="relative">                                
                          <label htmlFor={`delivery_status-${orders._id}`} className='pl-5 text-[0.725rem] text-[#363F6C] font-medium'>
                            <input type="checkbox" id={`delivery_status-${orders._id}`} className='absolute left-[0] top-[3px] w-[16px] h-[16px] border border-[#363F6C] rounded-[5px] appearance-none' onClick={(e)=>{changeDeliveryStatus('Yes');}}/>
                            Pending Delivery
                          </label>
                        </div>
                      </>
                      }
                    </>
                    }
                    </>
                  :
                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                  }
                  </>
                }
              </div>
          </div>
        </>
        :
        <>
          <div className="px-2.5 py-2.5 border-b border-black-100/10">
            <div className='text-[16px] leading-[24px] text-[#363F6C] font-bold mb-2.5'>
            {((orders.is_personalize!==undefined && orders.is_personalize) || (orders.color!==undefined && orders.color!=="") || (orders.gender!==undefined && orders.gender!=="") || (orders.sleeve!==undefined && orders.sleeve!=="") || (orders.size!==undefined && orders.size!=="")) ?
                <div className="flex gap-2.5">
                    <div>{orders.product_name}</div>
                    <button onClick={()=>{personalizeInformation()}} className="w-[20px] lg:w-[30px]">
                        <svg width="15" height="15" className="w-full" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#363F6C"/>
                            <path d="M16.9398 12.6015L13.1752 13.0733L13.0404 13.698L13.7802 13.8344C14.2635 13.9495 14.3588 14.1238 14.2536 14.6054L13.0404 20.3066C12.7215 21.7812 13.213 22.4749 14.3687 22.4749C15.2646 22.4749 16.3052 22.0607 16.7771 21.4919L16.9217 20.808C16.5929 21.0973 16.1129 21.2124 15.794 21.2124C15.3419 21.2124 15.1775 20.8951 15.2942 20.3362L16.9398 12.6015ZM17.0549 9.16895C17.0549 9.60495 16.8817 10.0231 16.5734 10.3314C16.2651 10.6397 15.8469 10.8129 15.4109 10.8129C14.9749 10.8129 14.5568 10.6397 14.2485 10.3314C13.9402 10.0231 13.767 9.60495 13.767 9.16895C13.767 8.73296 13.9402 8.31482 14.2485 8.00652C14.5568 7.69822 14.9749 7.52502 15.4109 7.52502C15.8469 7.52502 16.2651 7.69822 16.5734 8.00652C16.8817 8.31482 17.0549 8.73296 17.0549 9.16895Z" fill="#FBDB03"/>
                        </svg>                
                    </button>
                </div>
                :      
              <>{orders.product_name}</>
              }
            </div>
            {orders.installment_number!=="" &&
              <div className={`text-[12px] font-medium ${textColor}`}>{orders.installment_number}</div>
            }          
            <div className='orderinfo'>
                <div className="text-[12px] leading-[14px] flex mb-2.5">
                    <div className="flex-1 mr-1.5">
                    <span>{dayjs(orders.date).format("DD MMM YYYY")} by {orders.name}</span>
                    {(orders.notes!=="" && orders.notes!==undefined) &&
                      <div className="text-[10px]">{orders.notes}</div>
                    }
                    </div>
                    <div className='w-[100px] text-right'><span className={`rounded-full px-4 py-1 ${statusBtnColorCls}`}>{orders.status_title}</span></div>
                </div>
                <div className='text-[12px] leading-[16px] flex justify-between'>
                    {orders.status.toLowerCase() === 'requested' ? 
                      <strong className="text-[14px] lading-[21px] font-medium cursor-pointer text-[#363F6C]" onClick={()=>copyToClipboard(orders.stripe_id)}>Copy link</strong> 
                    : 
                    <>
                    {orders.status.toLowerCase() === 'succeeded' ? 
                      <>
                        {orders.stripe_id!==undefined && orders.stripe_id!=="" && orders.stripe_url!=="" ? 
                          <>
                          <span className="flex-1"><a href={orders.stripe_url} target="_blank" rel="noreferrer">
                            {orders.stripe_id.substring(0, 30)+'...'}
                          </a>
                          </span>
                          </>
                          : 
                          null
                        }
                      </>
                    :
                      <>
                        <span className="flex-1">{orders.status === 'Cash' ? orders.collected_by: (orders.stripe_id!==undefined && orders.stripe_id!=="" ? orders.stripe_id.substring(0, 30)+'...' : null)}</span>
                      </>
                    }
                    </>
                    }
                    {(orders.status.toLowerCase() === 'requested') &&
                      <>
                      {!loadingCancel ?
                      <button className="cancel-link rounded-full px-4 py-1 mb-2.5" onClick={()=>{cancelRequest();}}>Cancel Request</button>
                      :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                      }
                      </>
                    }
                    {(orders.status.toLowerCase() === 'succeeded' && orders._id !==undefined && orders._id !=='') &&
                      <>
                      {!loadingRefundstatus ?
                        <button className="cancel-link rounded-full px-4 py-1 mb-2.5 mr-4 max-w-[120px]" onClick={()=>{RefundOrder();}}>Refund</button>
                      :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                      }                
                      </>
                    }
                    {(orders.status.toLowerCase() === 'overdue') &&
                      <>
                      {!loadingSendPayment ?
                        <button className="cancel-link rounded-full px-2 py-1 mb-2.5  mr-4" onClick={()=>{sendPaymentRequest(orders._id, setLoadingSendPayment);}}>Send Request</button>
                      :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                      }
                      </>
                    }
                    {(orders.is_required_delivery.toLowerCase() === 'yes') &&
                      <>
                      {!loadingDstatus ?
                        <>
                        {(orderDeliveryStatus.toLowerCase() === 'yes') ?
                        <>
                          <div className="relative">                                
                              <label htmlFor={`delivery_status-${orders._id}`} className='pl-5 text-[0.725rem] text-[#363F6C] font-medium'>
                                  <input type="checkbox" id={`delivery_status-${orders._id}`} className='absolute left-[0] top-[3px] w-[16px] h-[16px] border border-[#363F6C] rounded-[5px] appearance-none' onClick={(e)=>{changeDeliveryStatus('No');}} defaultChecked/>
                                  Delivered
                              </label>
                          </div>
                        </>
                        :
                        <>
                          <div className="relative">                                
                              <label htmlFor={`delivery_status-${orders._id}`} className='pl-5 text-[0.725rem] text-[#363F6C] font-medium'>
                                  <input type="checkbox" id={`delivery_status-${orders._id}`} className='absolute left-[0] top-[3px] w-[16px] h-[16px] border border-[#363F6C] rounded-[5px] appearance-none' onClick={(e)=>{changeDeliveryStatus('Yes');}}/>
                                  Pending Delivery
                              </label>
                          </div>
                        </>
                        }
                        </>
                      :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                      }
                      </>
                    }
                </div>
            </div>
            {orders.installments!==undefined && orders.installments.length > 0 &&
              <button className="mt-4 underline hover:no-underline text-[12px]" onClick={()=>{setShowInstallmentSchedule(!showInstallMentSchedule)}}>View installments &gt;&gt;</button>
            }
          </div>
        </>
      }
      {showInstallMentSchedule &&
        <>
          <InstallmentSchedule installments={orders.installments} setShow={setShowInstallmentSchedule} />
        </>
      }
    </>
    );
}


export default OrderRow;