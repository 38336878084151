import { useState, useEffect } from "react";
import { getGroupMembers } from "../../libs/dataFunctions";
import Spinner from "../Spinner/Spinner";

const CoachesWithRoleInputV2 = ({teamID, token, parentsGroups, selectedCoaches, options, onChange, onChangeMain, className}) =>{
    const [showCoaches, setShowCoaches] = useState(false);
    const [editOptions, setEditOptions] = useState([]);
    const [editSelectedCoaches, setEditSelectedCoaches] = useState([]);
    
    useEffect(()=>{
        setEditSelectedCoaches(selectedCoaches);
    },[]);

    return(
        <>
        {editSelectedCoaches.length>0 ?
            <div className={`virtual-text-field ${className}`} onClick={()=>setShowCoaches(!showCoaches)}>
                {editSelectedCoaches.map((scoach)=>{
                    return(
                        <span key={scoach.sub}>{scoach.name}</span>
                    )
                })}
            </div>
        :
            <div className={`virtual-text-field ${className}`} onClick={()=>setShowCoaches(!showCoaches)}>Select Coaches</div>
        }
        {showCoaches &&
            <CoachesInputPopup 
                setShowForm={setShowCoaches} 
                setEditOptions={setEditOptions} 
                setEditSelectedCoaches={setEditSelectedCoaches}
                editSelectedCoaches={editSelectedCoaches}
                editOptions={editOptions}
                onChange={onChange}
                onChangeMain={onChangeMain}
                teamID={teamID}
                token={token}
                parentsGroups={parentsGroups}
            />
        }
        </>
    )
}
export default CoachesWithRoleInputV2;

const CoachesInputPopup = ({setShowForm, setEditOptions, setEditSelectedCoaches,  editSelectedCoaches, editOptions, onChange, onChangeMain, teamID, token, parentsGroups}) =>{
    const [loading, setLoading] = useState(false);
    const [searchParam] = useState(["name"]);
    const [groupBy, setGroupBy] = useState(parentsGroups[0].value);
    const [q, setSearchQuery] = useState("");
    const [showGroupsFilter, setShowGroupFilter] = useState(false);

    const handleUpdateCoaches=()=>{
        onChange(editSelectedCoaches);
        onChangeMain(editOptions);
        setShowForm(false);
    }

    const fetcMembers=async()=>{
        setLoading(true);
        const [teamMemberData] = await Promise.all([
            getGroupMembers(teamID, token, groupBy),
        ]);
        
        teamMemberData.forEach((tmMember, index)=>{
            const found = editSelectedCoaches.some(el => el.sub === tmMember.sub);
            if(found){
                var foundIndex = editSelectedCoaches.findIndex(x=> x.sub === tmMember.sub);
                tmMember.role=editSelectedCoaches[foundIndex].role;
                tmMember.custom_role=editSelectedCoaches[foundIndex].custom_role;
            }
        });

        setEditOptions(teamMemberData);
        setLoading(false);
    }

    useEffect(()=>{
        fetcMembers();
    },[groupBy]);

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    return(
        <div className="popup-form">
            <div className="popup-form-wrap relative !px-2.5 sm:!px-8 !max-w-[800px] !w-full">
                <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">Select Coaches</div>
                <div className="search-form-wrap flex mb-5 relative">
                    <label htmlFor="search-form" className="search-field relative flex-1 mr-2.5">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Now"
                            value={q}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);                                                            
                            }}
                        />
                    </label>
                    <button type="button" className={`button-icon button-icon-sort`} onClick={(e)=>{setShowGroupFilter(!showGroupsFilter);}}><span>Filter</span></button>
                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showGroupsFilter ? 'hidden' : ''}`}>
                        {parentsGroups.map((pg, index)=>{
                            return(
                                <li className={groupBy===pg.value ? 'active' : ''} key={index}>
                                    <button type="button" className='cursor-pointer w-full text-left' onClick={()=>{setGroupBy(pg.value); setShowGroupFilter(false);}}>{pg.label}</button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {!loading ?
                    <div className="options grid grid-cols-2 gap-5">
                        {search(editOptions).map((option, index)=>{
                            return(
                                <OptionRow key={option._id} rowOption={option} setEditSelectedCoaches={setEditSelectedCoaches} setEditOptions={setEditOptions} editSelectedCoaches={editSelectedCoaches} />                                
                            )
                        })}
                    </div>
                :
                    <Spinner />
                }
                <div className="flex justify-center">
                    <button className="button button-medium !mx-auto" type="button" onClick={()=>handleUpdateCoaches()}>DONE</button>
                </div>
            </div>
        </div>
    );
}

const OptionRow=({rowOption, setEditSelectedCoaches, setEditOptions, editSelectedCoaches})=>{
    const [selected,setSelected] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedCustomRole, setSelectedCustomRole] = useState('');
    const found = editSelectedCoaches.some(el => el.sub === rowOption.sub);    
    const handleOnChange=(e)=>{
        var updatedList = [...editSelectedCoaches];
        setSelected(e.target.checked);
        if(e.target.checked){
            updatedList = [...editSelectedCoaches, {'name':rowOption.name, 'sub':rowOption.sub, 'role':'','custom_role':''}];
            setEditSelectedCoaches(updatedList);
        } 
        else{
            var newList = updatedList.filter(editSelectedCoaches=> editSelectedCoaches.sub !== rowOption.sub);
            setEditSelectedCoaches(newList);
        }
    }
    
    const coach_roleList = ["LEAD COACH", "ASSISTANT COACH", "CAPTAIN", "TEAM MANAGER", "COORDINATOR", "OFFICIATOR", "REFEREE", "UMPIRE", "SCORER", "TIME KEEPER", "OTHER"];
    const handleRoleUpdate=(e)=>{
        setSelectedRole(e.target.value);            
        
        setEditSelectedCoaches(prevState => {
                const newState = prevState.map(obj => {
                  if (obj.sub === rowOption.sub) {
                    return {...obj, role: e.target.value};
                  }                
                  return obj;
                });                
                return newState;
            }
        );

        setEditOptions(prevState => {
            const newState = prevState.map(obj => {
              if (obj.sub === rowOption.sub) {
                return {...obj, role: e.target.value};
              }                
              return obj;
            });                
            return newState;
        });
    }

    const handleCustomRoleUpdate=()=>{
        setEditSelectedCoaches(prevState => {
            const newState = prevState.map(obj => {
                if (obj.sub === rowOption.sub) {
                    return {...obj, custom_role: selectedCustomRole};
                }          
                return obj;
            });                
            return newState;
        });
        
        setEditOptions(prevState => {
            const newState = prevState.map(obj => {
                if (obj.sub === rowOption.sub) {
                    return {...obj, custom_role: selectedCustomRole};
                }          
                return obj;
            });                
            return newState;
        });
    }

    useEffect(()=>{        
        if(found){
            setSelected(true);
            setSelectedRole(rowOption.role);
            setSelectedCustomRole(rowOption.custom_role);
        }
    },[]);

    return(
        <>
        <div className="option-row pb-2.5 border-b border-slate-500 mb-2.5 last:mb-0 last:border-b-0">
            <label htmlFor={`label-${rowOption.sub}`} className="flex justify-between cursor-pointer">
                <span className="flex-1 mr-2.5 text-[#363F6C] font-medium">{rowOption.name}</span>
                <input id={`label-${rowOption.sub}`} type="checkbox" checked={selected} onChange={(e)=>{handleOnChange(e)}} />
            </label>
            {selected &&
                <>
                <select name="coach_role" className="default-field-style" defaultValue={selectedRole} onChange={(e)=>{handleRoleUpdate(e);}}>
                    <option>Select role</option>
                    {coach_roleList.map((elm, index)=>{
                        return(
                            <>
                            <option value={elm} key={elm+index}>{elm}</option>
                            </>                            
                        )
                    })}
                </select>
                {selectedRole==='OTHER' &&
                    <div className="flex mt-3.5 justify-between">
                        <input type="text" className="default-field-style flex-1 mr-2.5" defaultValue={selectedCustomRole} onChange={(e)=>setSelectedCustomRole(e.target.value)} />
                        <button type="button" className="button button-medium" onClick={()=>handleCustomRoleUpdate()}>Apply</button>
                    </div>
                }
                </>
            }
        </div>
        </>
    )
}