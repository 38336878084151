import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import ProtectedRoute from './components/ProtectedRoutes';
import { AuthProvider } from './contexts/AuthProvider';
import Home from './pages/Home/Home';
import Settings from './pages/Settings/Settings';
import Team from './pages/Team/Team';
import NewsArticle from './pages/Team/News/NewsArticle';
import EventDetail from './pages/Team/Events/EventDetail';
import GroupsDetail from './pages/Team/Groups/GroupsDetail';
import ProductDetail from './pages/Team/Shop/ProductDetail';
import CreateTeam from './pages/Team/CreateTeam';
import CreateEvent from './pages/Team/Events/CreateEvent';
import CreateNews from './pages/Team/News/CreateNews';
import ShopOrders from './pages/Team/Shop/shopOrders';
import ShopCheckout from './pages/Team/Shop/ShopCheckout';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import MonthCalendar from './pages/MonthCalendar/MonthCalendar';
import CalendarDuties from './pages/CalendarDutiesv2/CalendarDutiesv2';
import SearchMapPage from './pages/SearchPage/SearchMap';
import Thankyou from './pages/Team/Shop/Thankyou';
import ProfilePage from './pages/Profile/Profile';
import LatestNewsArticle from './pages/Home/LatestNewsDetail';
import PendingRequests from './pages/PendingRequests/PendingRequests';
import NewsArticlePreview from './pages/Team/News/ArticlePreview';
import EventImport from './pages/Team/Events/EventImport';
import MemberImport from './pages/Team/Members/MemberImportForm';
import ProductOrders from './pages/Team/Shop/productOrders';
import MemberOrders from './pages/Team/Members/MemberOrders';
import AppThankyou from './pages/AppThankyou';
import MyCart from './pages/Team/Shop/MyCart';
import CreateFantacyGame from './pages/Team/FantacyGame/CreateFantacyGame';
import JoinFantacyGame from './pages/Team/FantacyGame/JoinGame';
import ChatPage from './pages/Team/ChatPage/ChatPage';
import AppPaymentFailed from './pages/AppPaymentFailed';
import LeaderShipBoard from './pages/Team/LeadershipBoard/LeadershipBoard';
import GameImport from './pages/Team/FantacyGame/GameImport';
import FantacyGameThankyou from './pages/FantacyGameThankyou';
import DuplicateNews from './pages/Team/News/DuplicateNews';
import DuplicateEvent from './pages/Team/Events/DuplicateEvent';
import EventDetailEdit from './pages/Team/Events/EventDetailEdit';
import YourFormResponse from './pages/Team/YourForms/YourFormResponse';
import AdminBookingsOverview from './pages/Team/Bookings/AdminBookingsOverview';
import ManageFacilities from './pages/Team/BookingFacilities/ManageFacilities';
import AddFacilities from './pages/Team/BookingFacilities/FacilityForm';
import YourBookings from './pages/Team/Bookings/YourBookings';
import MakeABooking from './pages/Team/Bookings/MakeBooking';
import AdminFormResponse from './pages/Team/YourForms/AdminFormResponse';
import AdminCreateForms from './pages/Team/YourForms/AdminCreateForm';
import AdminCloneForms from './pages/Team/YourForms/AdminCloneForm';
import AdminEditForms from './pages/Team/YourForms/AdminEditForm';
import ManageDiscountCodes from './pages/Team/DiscountCodes/ManageDiscountCodes';
import FixtureMigration from './pages/Team/FixtureMigration/FixtureMigration';
import ScoreCard from './pages/Team/Events/ScoreCard';
import EditFacilities from './pages/Team/BookingFacilities/FacilityEditForm';
import RequestPaymentPage from './pages/Team/RequestPayment/RequestPayment';
import Availability from './pages/Team/Availability/Availability';
import {GoogleOAuthProvider} from '@react-oauth/google';
import UpdateScore from './pages/Team/Events/UpdateScore';

function App() {
  document.title = 'PLAI Sport';
  useEffect(() => {
    const tabKey = "openTabs"; // Key for tracking open tabs
    const variableKey = "oneteamflag"; // Key for the variable you want to manage

    const incrementTabCount = () => {
      const count = parseInt(localStorage.getItem(tabKey) || "0", 10);
      localStorage.setItem(tabKey, count + 1);
    };

    const decrementTabCount = () => {
      const count = parseInt(localStorage.getItem(tabKey) || "0", 10);
      const newCount = Math.max(count - 1, 0);
      localStorage.setItem(tabKey, newCount);

      if (newCount === 0) {
        localStorage.removeItem(variableKey);
      }
    };

    // Initialize tab count
    incrementTabCount();

    // Cleanup tab count on tab close
    window.addEventListener("beforeunload", decrementTabCount);

    // Cleanup on component unmount
    return () => {
      decrementTabCount();
      window.removeEventListener("beforeunload", decrementTabCount);
    };
  }, []);
  
  return (
    <>
      <div className="App">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID}>
          <AuthProvider>
            <Routes>
              <Route exact path='/' element={<ProtectedRoute />}>
                <Route exact path='/' element={<Home />} />
                <Route path="/latest/:id" element={<LatestNewsArticle />} />
                <Route path="/team/:id/fantasygame" element={<CreateFantacyGame />} />
                <Route path="/team/:id/chat" element={<ChatPage />} />
                <Route path="/team/:id/chat/:group_id" element={<ChatPage />} />              
                <Route path="/team/:id/import-game" element={<GameImport />} />
                <Route path="/team/:id/joinfantasygame" element={<JoinFantacyGame />} />
                <Route path="/team/:id/leadershipboard" element={<LeaderShipBoard />} />
                <Route path="/team/:id/import-events" element={<EventImport />} />
                <Route path="/team/:id/import-members" element={<MemberImport />} />
                <Route path="/team/:id/news/duplicate-news" element={<DuplicateNews />} />
                <Route path="/team/:id/news/preview" element={<NewsArticlePreview />} />
                <Route path="/team/:id/news/:newsid" element={<NewsArticle />} />
                <Route path="/team/:id/:tab" key={Date.now()} element={<Team />} />
                <Route path="/team/:id" key={Date.now()} element={<Team />} />
                <Route path="/team/:id/events/create-event" element={<CreateEvent />} />
                <Route path="/team/:id/events/duplicate-event" element={<DuplicateEvent />} />
                <Route path="/team/:id/news/create-news" element={<CreateNews />} />
                <Route path="/team/:id/events/selection" element={<Availability />} />
                <Route path="/team/:id/event/:eventid" element={<EventDetail />} />
                <Route path="/team/:id/event/:eventid/edit" element={<EventDetailEdit />} />
                <Route path="/team/:id/event/:eventid/scorecard" element={<ScoreCard />} />
                <Route path="/team/:id/event/:eventid/update_score" element={<UpdateScore />} />
                <Route path="/team/:id/fixture-migration" element={<FixtureMigration />} />
                <Route path="/team/:id/group/:groupid" element={<GroupsDetail />} />
                <Route path="/team/:id/shop/:productid" element={<ProductDetail />} />
                <Route path="/team/:id/shop/:productid/orders" element={<ProductOrders />} />
                <Route path="/team/:id/shop/mycart" element={<MyCart />} />
                <Route path="/team/:id/shop/checkout" element={<ShopCheckout />} />
                <Route path="/team/:id/shop/manage-discount-codes" element={<ManageDiscountCodes />} />
                <Route path="/team/:id/shop/orders" element={<ShopOrders />} />
                <Route path="/team/:id/members/:memberid" element={<MemberOrders />} />
                <Route path="/team/:id/your-form/:formid" element={<YourFormResponse />} />
                <Route path="/team/:id/formview/:formid" element={<AdminFormResponse />} />
                <Route path="/team/:id/duplicateform/:formid" element={<AdminCloneForms />} />
                <Route path="/team/:id/editform/:formid" element={<AdminEditForms />} />
                <Route path="/team/:id/create-form" element={<AdminCreateForms />} />
                <Route path="/team/:id/request-payment/:pid" element={<RequestPaymentPage />} />
                
                <Route path="/team/:id/existing-bookings" element={<AdminBookingsOverview />} />
                <Route path="/team/:id/manage-facilities" element={<ManageFacilities />} />
                <Route path="/team/:id/add-facilities" element={<AddFacilities />} />
                <Route path="/team/:id/your-bookings" element={<YourBookings />} />
                <Route path="/team/:id/make-a-booking" element={<MakeABooking />} />
                <Route path="/team/:id/edit-facility/:facility_id" element={<EditFacilities />} />              
                
                <Route path="/create-team" element={<CreateTeam />} />
                <Route path="/settings/:tab" element={<Settings />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/calendar" element={<CalendarDuties />} />
                <Route path="/calendar/month" element={<MonthCalendar />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/search-page" element={<SearchMapPage />} />
                <Route path="/pending-requests" element={<PendingRequests />} />              
              </Route>
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
              <Route path="/app-thankyou" element={<AppThankyou />} />
              <Route path="/app-paymentfailed" element={<AppPaymentFailed />} />
              <Route path="/fantasy-thankyou" element={<FantacyGameThankyou />} />
            </Routes>
          </AuthProvider>
        </GoogleOAuthProvider>
      </div>
    </>
  );
}

export default App;