import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../contexts/AuthProvider';
import placeholderImg from '../../assets/images/placeholder.png';
import IsMobile from '../isMobile';
import Wallet from '../Wallet/Wallet';
import WalletStatement from '../Wallet/WalletStatement';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './AdminNavbar.scss';

export default function AdminNavbar({heading}){
    const navigate = useNavigate();
    const [showWalletPopup, setShowWalletPopup]=useState(false);
    const [showWalletStatement,setShowWalletStatement] = useState(false);

    const userDetails = useAuthState() //read user details from context
    const handleClickMobmenu = (event) => {
        document.getElementById('sidebar-menu').classList.toggle('expand');
        const menuBtn = document.getElementsByClassName('mobile-nav');
        for (let i = 0; i < menuBtn.length; i++) {
            menuBtn[i].classList.toggle('expand');
        }
    };
    
    const openSearchPage=()=>{
        navigate('/search-page');
    }
    const ismobile = IsMobile(window);
    return(
        <>
            <nav className="admin-navbar">
                <div className="container max-w-full mx-auto"> 
                    <div className="flex justify-between items-center w-full py-3 pl-5 pr-5 lg:pr-7">
                        <h4 className="title">{heading ? heading : 'Dashboard'}</h4>
                        <div className="flex items-center md:w-full md:max-w-[350px] lg:max-w-[350px]">
                            <div className="search-bar relative flex items-center mr-2.5 lg:mr-5 py-2">
                                <span className="search-icon"></span>
                                <input placeholder="Search for a team..." onClick={()=>openSearchPage()} className="search-input w-full focus:outline-none focus:ring-0 bg-[#f4f5fa]" />
                            </div>
                            <button id="adminav-wallet" type="button" className={`button-icon button-icon-wallet mr-2`} onClick={(e)=>setShowWalletPopup(!showWalletPopup)}><span>Wallet</span></button>
                            <Tooltip anchorSelect="#adminav-wallet" className="z-10 text-sm">My Wallet</Tooltip>
                            <a href="/calendar" id="adminav-calendar" className={`button-icon button-icon-calendar mr-2`}><span>Sort</span></a>
                            <Tooltip anchorSelect="#adminav-calendar" className="z-10 text-sm">Event Calendar</Tooltip>
                            <div className='profile mr-2 lg:mr-0 w-[32px]'>
                                <a id="manage-profile" href="/profile">
                                    {userDetails?.user?.profilePic !== "" ?
                                    <img src={userDetails?.user?.profilePic} className="rounded-lg" alt="" width={32} height={32} />
                                    :
                                    <img src={placeholderImg} alt="" className="rounded-lg" width={32} height={32} />
                                    }
                                </a>
                                <Tooltip anchorSelect="#manage-profile" className="z-10 text-sm">Manage Profile</Tooltip>
                            </div>
                            {/*
                            <div className='menu'>
                                <img src={menu} alt="" width={3} height={19} />
                            </div>
                            */}
                            <button type='button' className='mobile-nav' onClick={(e)=>handleClickMobmenu(e)}><span className='hidden'>Menu</span></button>
                        </div>
                    </div>
                </div>
            </nav>
            {showWalletPopup &&
                <Wallet setShowForm={setShowWalletPopup} setShowWalletStatement={setShowWalletStatement} />
            }
            {showWalletStatement &&
                <WalletStatement setShowForm={setShowWalletStatement} />
            }
        </>
    )
}