import { useState } from "react";
import dayjs from "dayjs";
import { useAuthDispatch, updateAdminRequest } from "../../contexts/AuthProvider";

const AdminUpdateRequest=({setShowForm, requestData})=>{
    const [loading,setLoading] = useState(false);
    const dispatch = useAuthDispatch();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const updateRequest = async(is_accept_decline) => {
        setLoading(true);
        var formdata = new FormData();
        
        formdata.append("type", "accept_decline_admin_update_request");
        formdata.append("admin_update_request_id", requestData._id);
        formdata.append("is_accept_decline",is_accept_decline);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');  
        
        try {
            let response = await updateAdminRequest(dispatch, formdata, secureHeader);
            if (response.status !== 0){                
                setTimeout(function(){
                    setLoading(false);
                    setShowForm(false);
                    window.location="/";
                },5000);                
            }
            else{
                setLoading(false);
                setShowForm(false);                
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 md:!px-8">
                <div className="form-heading text-center mb-7">THEY ADMIN AT {requestData.club_name} HAS ADJUSTED YOUR PERSONAL INFORMATION ON PLAI, DO YOU ACCEPT THESE CHANGES TO YOUR ACCOUNT ON PLAI?</div>
                <label className="w-full text-[#363F6C] font-medium text-sm">Phone</label>
                <div className='form-field-wrap mb-5 select-field-wrap phone-field-wrap relative'>
                    <span className="py-3.5 pl-[45px] w-full block bg-[#EFF1F9] rounded-[8px]">{requestData.phoneCode!=="" ? requestData.phoneCode+' '+requestData.phoneNumber : requestData.phoneNumber}</span>
                </div>
                <label className="w-full text-[#363F6C] font-medium text-sm">Date Of Birth</label>
                <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                    <span className="py-3.5 pl-[45px] w-full block bg-[#EFF1F9] rounded-[8px]">{(requestData.dateOfBirth!=='' && requestData.dateOfBirth!==null) ? dayjs(requestData.dateOfBirth).format('DD / MMM / YYYY'): (requestData.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</span>
                </div>
                {!loading ?
                    <div className="flex gap-4 justify-center">
                        <button className="button button-medium button-red" onClick={()=>updateRequest('No')}>Decline</button>
                        <button className="button button-medium button-green" onClick={()=>updateRequest('Yes')}>Accept</button>
                    </div>
                    :
                    <div className="text-center"><img className="mx-auto" src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" /></div>
                }
            </div>
        </div>
        </>
    )
}

export default AdminUpdateRequest;