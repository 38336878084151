import { useState } from 'react';
import { useForm } from 'react-hook-form';
import placeholderImg from '../../../assets/images/placeholder.png';
//import iconDel from '../../../assets/images/icon-teamdel.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const MyTeamRow=({myteam, setLoading, refreshMyTeams, isDelete})=>{
    const [showPrompt,setShowPrompt] = useState(false);
    const [leaveLoading,setLeaveLoading] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const schema = yup.object({
        password: yup.string().required('Please Enter your password'),
    }).required();

    const DeletePromptForm = ({setShowForm}) =>{
        const [ploading, setPLoading] = useState(false);
        const [errorMessage, setErrorMessage] = useState(false);
        const [showPassword, setShowPassword] = useState(false);
        const { register, handleSubmit, formState: { errors } } = useForm({
            resolver: yupResolver(schema)
        });
        const onSubmit = async (data) => {
            setPLoading(true);
            setErrorMessage(false);
            var formdata = new FormData();
            formdata.append("type", "delete_team");
            formdata.append("team_id", myteam._id);
            formdata.append("password", data.password);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    refreshMyTeams();
                }
                else{
                    setPLoading(false);
                    setErrorMessage(true);
                }
            })
            .catch(error => console.log('error', error));
        }
        const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
        };
    
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[400px] relative">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className='text-center font-semibold mb-5 px-4'>Please enter your password to delete the team</div>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">  
                            <div className='auth-form-field-wrap mb-5 password-field-wrap relative'>
                                <input 
                                    type={showPassword ? "text" : "password"} disabled={ploading} placeholder="Password" className='password-field' onFocus={()=>{setErrorMessage(false)}}
                                    {...register("password")} 
                                    aria-invalid={errors.password ? "true" : "false"}
                                />
                                <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPassword ? 'toggled' : ''}`} onClick={handleClickShowPassword} id="passwordTrigger"></span>
                            </div>
                            {errors.password && <p className="error mb-2.5" role="alert">{errors.password?.message}</p>}
                            {!ploading && errorMessage ? <p className='w-full error text-center mb-2.5'>You've entered an incorrect password.</p> : null}
                            <div className="w-full px-2.5">
                                <div className='flex justify-center'>
                                {!ploading ?
                                    <input className='button' type="submit" value="Confirm" disabled={ploading}/>
                                    :
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                }
                                </div>
                            </div>
                    </form>
                </div>
            </div>
        )
    }

    const leaveTeam = async ()=>{
        
        if(window.confirm("Are you sure you want to leave this team?")){
            setLeaveLoading(true);
            var formdata = new FormData();
            formdata.append("type", "leave_team");
            formdata.append("team_id", myteam._id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            var apipoint=process.env.REACT_APP_APIURL + '/app_teams';
            
            await fetch(apipoint , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLeaveLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    refreshMyTeams();
                }
                else{
                    setLeaveLoading(false);
                }
            })
            .catch(error => console.log('error', error));
        }
    }

    return(
        <>
        <div className="team flex-col flex sm:flex-row items-center sm:items-start p-3.5 cursor-pointer">
            <div className='logo-wrapper relative w-[80px] h-[80px] mb-2.5 sm:mb-0 sm:mr-5 inline-block rounded-full overflow-hidden'>
                {myteam.logo && myteam.logo!=="" ?
                    <img src={myteam.logo} alt={myteam.name} className="absolute object-cover w-full h-full"/>
                :
                    <img src={placeholderImg} alt={myteam.name} className="absolute object-cover w-full h-full"/>
                }
            </div>
            <div className='info sm:text-left text-center w-full md:auto sm:flex-1'>
                <h5 className="title">{myteam.name}</h5>
                <p className={`role ${myteam.role==='admin' ? 'isadmin' : ''} mb-2.5 table mx-auto sm:mx-0 uppercase`}>{myteam.role}</p>
                <div className='flex justify-between w-full'>
                    {myteam?.members?.length > 0 &&
                    <div className="members">
                        {myteam?.members.map((teamMember,index)=>{
                            if(index > 4){return ''}
                            let eclass='';
                            let profilePic='';
                            profilePic=(teamMember.profilePicture && teamMember.profilePicture !== "") ? teamMember.profilePicture : placeholderImg;
                            if(index!==0){
                                eclass = '-ml-2.5';
                            }                        
                            return <img src={profilePic} key={teamMember._id} alt="" className={`inline-block relative object-cover object-center rounded-full w-7 h-7 ${eclass}`} />
                        })}
                    </div>
                    }
                    {isDelete ?
                        <>
                        <div className='justify-self-end'>
                            <button type='button' className='button button-medium' onClick={()=>{setShowPrompt(true)}}>DELETE</button>
                        </div>
                        {showPrompt &&
                            <DeletePromptForm setShowForm={setShowPrompt} />
                        }
                        </>
                    :
                        <>
                            <div className='justify-self-end'>
                            {!leaveLoading ?
                                <button type='button' className='button button-medium' onClick={()=>{leaveTeam();}}>LEAVE</button>
                                :
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                            }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
        </>
    )
}
export default MyTeamRow;